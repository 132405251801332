import { ResultPaginated } from '../../paginated_result';

export interface RequestPage {
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortAsc: boolean;
}

export function getPagedDataFromArray<T>(array: T[], request: RequestPage): ResultPaginated<T> {
  if (!request.sortAsc) {
    array.reverse();
  }
  const length = array.length;
  const pageStart = request.pageIndex * request.pageSize;
  const pageEnd = pageStart + request.pageSize;
  const pageCount = Math.trunc(length / request.pageSize) + 1;
  const slice = array.slice(pageStart, Math.min(length, pageEnd));

  return {
    count: length,
    pageCount: pageCount,
    pageIndex: request.pageIndex,
    pageSize: request.pageSize,
    results: slice
  };
}

export function equalsIgnoringCase(str1: string, str2: string): boolean {
  return str1.localeCompare(str2, undefined, {sensitivity: 'base'}) === 0;
}
