import { Modal } from 'bootstrap';
import { tlang } from '@softtech/webmodule-components';
import { ModalPromise } from '@softtech/webmodule-data-contracts';
import { DevelopmentError } from '../../development-error';

export interface ModalExecutor {
  show: (item: HTMLElement) => void;
  hide: (item: HTMLElement) => void;
}

/**
 * ModalTracker used to track modal state and z-index so we can always take the next highest value
 */
interface ModalTracker {
  modal: any;
  zindex: number;
  ignoreZIndexForAllocatingNext: boolean;
}

/**
 * this is our base offset for a modal dialog that is set inside the css file.
 * if we are the first modal we dont need to do anything
 */
const initialModalPosition = 1055;

let modalTracker: ModalTracker[] = [];
/**
 * used to prevent running code multiple times if events are triggered more than one time.
 * @param modal modal to see if we are already tracking it.
 * @returns
 */
function hasModal(modal: any): boolean {
  const m = modalTracker.find(x => x.modal === modal);
  return m !== undefined;
}
/**
 *
 * @param modal modal to remove from tracking when it is hidden
 */
function removeModal(modal: any) {
  modalTracker = modalTracker.filter(x => x.modal !== modal);
}
/**
 *
 * @returns the next available maximum z-index to ensure new modals raise to the top
 */
export function nextModalStackZIndex(): number {
  let retVal = initialModalPosition;
  if (modalTracker.length === 0) return retVal;
  modalTracker.forEach(x => {
    if (!x.ignoreZIndexForAllocatingNext) retVal = Math.max(retVal, x.zindex);
  });
  return retVal + 1;
}

export function currentModalStackZIndex() {
  return nextModalStackZIndex() - 1;
}

export function pushOntoModalZIndexStack(item: any, zIndex: number): number {
  modalTracker.push({
    modal: item,
    zindex: zIndex,
    ignoreZIndexForAllocatingNext: false
  });
  return zIndex;
}
export function popFromModalZIndexStack(item: any) {
  if (hasModal(item)) removeModal(item);
}

const modalLauncher: ModalExecutor = {
  show: (modalElement: HTMLElement) => {
    Modal.getOrCreateInstance(modalElement).show();
  },
  hide: (modalElement: HTMLElement) => {
    Modal.getOrCreateInstance(modalElement).hide();
  }
};

/**
 *
 * @returns returns the latest backdrop created by bootstrap that has not been taken over by this tool
 */
function findBackDrop(): HTMLDivElement | null {
  let backDrop: HTMLDivElement | null | undefined;

  const nodes = document.querySelectorAll('.modal-backdrop');
  nodes.forEach(element => {
    if (element instanceof HTMLDivElement && !element.classList.contains('managed-backdrop')) backDrop = element;
  });
  if (backDrop) {
    backDrop.classList.add('managed-backdrop');
    return backDrop;
  }
  return null;
}

/**
 * bootstrap doesn't support nesting of modals. but we need to support nesting of modals
 * elevate modal is going to increment the zindexes for the modal, and its backdrop
 * and add it to our modal stack
 * @param modalDialog
 * @param _zIndex
 */
function elevateModal(modalDialog: HTMLElement, _zIndex?: number) {
  modalDialog.addEventListener('shown.bs.modal', () => {
    if (hasModal(modalDialog)) return;
    const zIndex = _zIndex ?? nextModalStackZIndex();
    modalTracker.push({
      modal: modalDialog,
      zindex: zIndex,
      ignoreZIndexForAllocatingNext: _zIndex !== undefined
    });
    const backDrop = findBackDrop();

    //we only need to hack the z-order if we are not the lowest setting
    if (zIndex > initialModalPosition) {
      modalDialog.style.zIndex = zIndex.toString();
      if (backDrop) backDrop.style.zIndex = (zIndex - 1).toString();
    }
  });
}
/**
 * when we are closing a modal with nesting we need to ensure that the body retains its class if any modal is still open
 * bootstrap always assumes only one modal
 */
function fixBackDrop() {
  const modal = document.body.querySelector('.modal.show');
  if (modal && !document.body.classList.contains('modal-show')) document.body.classList.add('modal-show');
  else if (!modal && document.body.classList.contains('modal-show')) document.body.classList.remove('modal-show');
}
/**
 * remove a modal and hide it
 * @param ui an element who is a standalone wrapper to a modal
 */
export function hideModalDialog(ui: HTMLElement, closePromise?: Promise<void>): Promise<void> {
  const modalDialog = ui.querySelector('.modal') as HTMLElement;
  if (!modalDialog) return Promise.resolve();
  if (!hasModal(modalDialog)) return Promise.resolve();
  const promise =
    closePromise ??
    new Promise<void>(resolve => {
      modalDialog.addEventListener('hidden.bs.modal', () => {
        resolve();
      });
    });
  modalLauncher.hide(modalDialog);
  return promise;
}

/**
 * a promise that returns after the modal is disposed of. this takes a wrapper div object that contains
 * a modal, typically created using Render(modalTemplate, divElement)
 * this will bind the modal into the modal tracking system and elevate its zindex to ensure nested modals
 * all behave correctly with backdrops
 * @param ui
 * @param zIndex
 * @returns
 */

export function showModalDialogEx(ui: HTMLElement, zIndex?: number): ModalPromise {
  const modalDialog = ui.querySelector('.modal') as HTMLElement;
  if (!modalDialog) throw new DevelopmentError('Element provided does not contain a modal dialog');
  document.body.appendChild(ui);
  elevateModal(modalDialog, zIndex);

  const onClose = new Promise<void>(resolve => {
    modalDialog.addEventListener('hidden.bs.modal', () => {
      //sometimes these events can double trigger.
      //so verify that we have something to work on.
      if (hasModal(modalDialog)) {
        ui.remove();
        removeModal(modalDialog);
        fixBackDrop();
        resolve();
      }
    });
  });
  const onShow = new Promise<void>(resolve => {
    modalDialog.addEventListener('shown.bs.modal', () => {
      resolve();
    });
  });

  modalLauncher.show(modalDialog);
  return {
    onClose: onClose,
    onShow: onShow
  };
}
export async function showModalDialog(ui: HTMLElement, zIndex?: number): Promise<void> {
  const modalDialog = ui.querySelector('.modal') as HTMLElement;
  if (!modalDialog) throw new Error(tlang`Element provided does not contain a modal dialog`);
  document.body.appendChild(ui);
  elevateModal(modalDialog, zIndex);

  return new Promise<void>(resolve => {
    modalLauncher.show(modalDialog);
    modalDialog.addEventListener('hidden.bs.modal', () => {
      //sometimes these events can double trigger.
      //so verify that we have something to work on.
      if (hasModal(modalDialog)) {
        ui.remove();
        removeModal(modalDialog);
        fixBackDrop();
        resolve();
      }
    });
  });
}
