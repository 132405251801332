import { tlang } from '@softtech/webmodule-components';
import { ContainerManager } from '../../components/container-manager';
import { EventSnippet } from '@softtech/webmodule-data-contracts';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { DevelopmentError } from '../../development-error';

import { getFileContent, getSettingsManager } from '../../supplier/common/supplier-settings';
import { getApiFactory } from '../../api/api-injector';
import { stringToBase64 } from '../../blob/converters';

export class FileContainer {
  virtualFilePath: string;
  content?: string;

  constructor(virtualFilePath: string, content?: string) {
    this.virtualFilePath = virtualFilePath;
    this.content = content;
  }
}

export class FileContainerManager extends ContainerManager<FileContainer> {
  protected settingsManager: SupplierSettingsManager = getSettingsManager();
  skipUpload = true;

  constructor(container: FileContainer, title: EventSnippet) {
    super(container, title, tlang`file`);
  }

  public set fileContent(value: string) {
    this.container.content = value;
  }

  public get fileContent(): string {
    return this.container.content ?? '';
  }

  public get virtualFilePath(): string {
    if (!isEmptyOrSpace(this.container.virtualFilePath)) {
      return this.container.virtualFilePath;
    }
    throw new DevelopmentError('file-content-manager.ts virtualFilePath is missing from container');
  }

  public async needsFile(): Promise<boolean> {
    const result = await getFileContent(this.container.virtualFilePath);
    if (result) {
      this.fileContent = result;
      this.backup.content = this.clone(result);
      return true;
    }
    return false;
  }

  public async uploadFile(object: any, filePath: string, converter: (value: any) => string | null): Promise<boolean> {
    const data = converter(object);
    if (!data) {
      throw new DevelopmentError(tlang`supplier-settings.ts Unable to convert object for saving`);
    }

    try {
      const result = await getApiFactory().blob().updateFileByVirtualPath({
        data: data,
        newVirtualPath: filePath,
        oldVirtualPath: ''
      });
      if (result) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      throw new DevelopmentError(tlang`supplier-settings.ts Unable to save file '${filePath}'`);
    }
  }
  protected async internalSave(): Promise<boolean> {
    if (!this.skipUpload) {
      return await this.uploadFile(this.fileContent, this.virtualFilePath, value => stringToBase64(value));
    }
    return true;
  }
}
