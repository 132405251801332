import { DashboardWidget } from './dashboard-widget';
import { customElement, property } from 'lit/decorators.js';
import { tlang } from '@softtech/webmodule-components';
import { Snippet } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import { getLicenseDataByBranch } from '../../common/supplier-license-data';

const ApexCharts = (globalThis as any).ApexCharts;

@customElement('active-licenses-widget')
export class ActiveLicensesWidget extends DashboardWidget {
  @property() franchiseeDeploymentConfigurationId: string = '';
  @property() maxLicenses: number = 0;
  @property() availableLicenses: number = 0;
  @property() branchIds?: string[] = [];

  chart: any | null = null;

  protected async refreshData(): Promise<void> {
    const licenseData = await getLicenseDataByBranch(undefined, this.branchIds);

    this.maxLicenses = licenseData.licensesAllocatedToDealer;
    this.availableLicenses = licenseData.licensesAllocatedToDealer - licenseData.licensesUsedByDealer;
  }

  protected async firstUpdated(): Promise<void> {
    await this.refreshData();
    const options = {
      series: [this.activeLicenseCount, this.availableLicenses],
      labels: [tlang`%%license-state-active%%`, tlang`%%license-state-available%%`],
      colors: ['#B95765', '#C7CBD0'],
      chart: {
        type: 'donut',
        height: '120%',
        offsetY: 0
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        expandOnClick: false,
        pie: {
          donut: {
            size: '60%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: tlang`%%license-state-active%%`,
                formatter: () => this.getChartLabel()
              },
              name: {
                show: false
              },
              value: {
                show: true
              }
            }
          }
        }
      },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      }
    };
    this.chart = new ApexCharts(this.querySelector('.licenseChart'), options);
    this.chart.render();
  }

  protected getHeader(): Snippet {
    return tlang`Active Licenses`;
  }

  protected getBody(): Snippet {
    return html`
      <div class="container">
        <div class="row chart-widget-data-wrapper">
          <div class="col-6 chart-widget-data">
            <div class="display-5 text-primary">${this.activeLicenseCount}</div>
            <div class="display-6 text-secondary">of ${this.maxLicenses} Granted</div>
          </div>
          <div class="col-6 chart-widget-chart">
            <div class="licenseChart"></div>
          </div>
        </div>
      </div>
    `;
  }

  protected get activeLicenseCount(): number {
    return this.maxLicenses - this.availableLicenses;
  }

  protected getChartLabel(): string {
    const result = ((this.activeLicenseCount + Number.EPSILON) / this.maxLicenses) * 100;
    const percentage = Math.round(result);
    if (Number.isFinite(percentage)) {
      return `${percentage}%`;
    }
    return `0%`;
  }
}
