//Provide a global abstraction from the implementation details of an api call

import { ApiCommunications } from '@softtech/webmodule-data-contracts';

import { SupplierApi } from './supplier-api';
import { AuthenticationOptions, getCurrentUser } from '@softtech/webmodule-components';
import { FranchiseeNetworkApi } from './franchisee-network-api';
import { BlobApi } from './blob-api';
import { DataAggregationApi } from './supplier-api-dataaggregation';

export type ApiProvider = () => ApiCommunications;

export interface ApiFactory {
  supplier: () => SupplierApi;
  franchisee: () => FranchiseeNetworkApi;
  blob: () => BlobApi;
  dataAggregation: () => DataAggregationApi;
}

let _apiInjector: ApiProvider = () => {
  throw new Error('please call setApiInjector');
};

let _apiFactory: ApiFactory = {
  supplier: () => {
    throw new Error('please call setApiInjector - supplier api not registered');
  },
  franchisee: function (): FranchiseeNetworkApi {
    throw new Error('please call setApiInjector - dealer api not registered');
  },
  blob: function (): BlobApi {
    throw new Error('please call setApiInjector - blob api not registered');
  },
  dataAggregation: function (): DataAggregationApi {
    throw new Error('please call setApiInjector - DataAggregation api not registered');
  }
};

export function setApiInjector(apiInjector: ApiProvider) {
  _apiInjector = apiInjector;
}

export function getApi(): ApiCommunications {
  return _apiInjector();
}

export function setApiFactory(factory: ApiFactory) {
  _apiFactory = factory;
}

export function getApiFactory() {
  return _apiFactory;
}

export function authenticationOptions(): AuthenticationOptions {
  return {
    api: getApi(),
    apiHost: globalThis.dealerConfiguration.apiHost,
    licenseServerHost: globalThis.dealerConfiguration.licenseServerHost,
    branding: globalThis.dealerConfiguration.branding,
    deploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
    user: getCurrentUser()
  };
}
