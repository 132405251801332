// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { googleMapApiKey } from '../../../api/google-api';

import { Place } from './place';
import { DataBinding } from '../databinding/databinding';
import { isEmptyOrSpace } from '../helper-functions';
import { Address } from '../../../api/misc-codegen';

/**
 * Html template result for the display of Google Maps, based on the Lat and Lng values.
 * @param lat
 * @param lng
 */
export function mapTemplate(lat: number | undefined, lng: number | undefined): TemplateResult {
  return lat && lng
    ? html`
        <google-map
          api-key=${googleMapApiKey}
          zoom="16"
          fit-to-markers="true"
          .centerLatitude="${lat}"
          .centerLongitude="${lng}"
        >
          <google-map-marker slot="markers" .latitude="${lat}" .longitude="${lng}"> </google-map-marker>
        </google-map>
      `
    : html`<img src="./assets/images/map_placeholder.png" style="max-width:100%;" />`;
}

/**
 * Empty address object for the creation of clients, Projects, etc.
 */

export function emptyAddress(): Address {
  return {
    longitude: null,
    latitude: null,
    country: '',
    line1: '',
    line2: null,
    line3: null,
    line4: null,
    region: '',
    locality: '',
    postcode: '',
    source: null
  };
}

/**
 * Checks to see if the given address is empty or null.
 * @param address The address to be checked.
 * @returns True if the address object is null or its fields are empty, False otherwise.
 */
export function isAddressNullOrEmpty(address?: Address | null): boolean {
  if (address) {
    return (
      isEmptyOrSpace(address.line1) &&
      isEmptyOrSpace(address.locality) &&
      isEmptyOrSpace(address.postcode) &&
      isEmptyOrSpace(address.region) &&
      isEmptyOrSpace(address.country)
    );
  }
  return true;
}

export function isAddressValid(address: Address): boolean {
  return !isAddressNullOrEmpty(address)
      && !isEmptyOrSpace(address.postcode)
      && !isEmptyOrSpace(address.line1);
}

export function singleLineAddress(address?: Address): string {
  if (!address) return '';
  return [
    address.line1,
    address.line2,
    address.line3,
    address.line4,
    address.locality,
    address.region,
    address.postcode,
    address.country
  ]
      .filter(Boolean)
      .join(', ');
}

/**
 * This helper function will update the Line1, Locality, Region, Postcode and Country fields on the data-binder.
 * It will also return the Lat and Lng values for the address.
 * @param address Updated map values from lookup.
 * @param databinding
 */
export function updateAddressDataBinding(
  address: Place,
  databinding: DataBinding
): null | { lat: number; lng: number } {
  if (address) {
    const latPhysical = address.latLng.lat;
    const lngPhysical = address.latLng.lng;

    databinding.setValue('line1', address.basic.address);
    databinding.setValue('locality', address.basic.city);
    databinding.setValue('region', address.basic.state);
    databinding.setValue('postcode', address.basic.postalCode);
    databinding.setValue('country', address.basic.country);

    return { lat: latPhysical, lng: lngPhysical };
  }

  return null;
}

export function updateAddress(place: Place, address: Address): Address {
  if (place) {
    address.line1 = place.basic.address;
    address.locality = place.basic.city;
    address.region = place.basic.state;
    address.postcode = place.basic.postalCode;
    address.country = place.basic.country;
    address.latitude = place.latLng.lat.toString();
    address.longitude = place.latLng.lng.toString();
  }

  return address;
}

export function areStreetAddressesEqual(address1: Address, address2: Address): boolean {
  return (
    address1.line1 === address2.line1 &&
    address1.locality === address2.locality &&
    address1.region === address2.region &&
    address1.postcode === address2.postcode &&
    address1.country === address2.country
  );
}
