import { html, TemplateResult } from 'lit';
import { emptyGuid } from '../../api/guid';
import { tlang } from '@softtech/webmodule-components';
import { resolveURL } from './resource-resolver';

export function validId(value: string | null | undefined): boolean {
  return !isEmptyOrSpace(value);
}

export function sameText(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

export function containsText(value: string, searchTerm: string): boolean {
  return value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
}

export function isEmptyOrSpace(value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === '' || value.trim() == emptyGuid;
}

export function isValidEmail(mail: string): boolean {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

function displayBoolean(yes: string, no: string, value?: boolean) {
  return value ? yes : no;
}

export function displayYesNo(value?: boolean) {
  return displayBoolean(tlang`Yes`, tlang`No`, value);
}

export function bsFormRadioGroupYesNoOptions() {
  const value = JSON.stringify([
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' }
  ]);
  return value;
}

export function firstValidString(...args) {
  for (let i = 0; i < args.length; i++) if (!isEmptyOrSpace(args[i])) return args[i];
  return '';
}

export interface ClosePageAction {
  url: string;
  template: TemplateResult;
}

export function closePageAction(
  primaryType: string,
  primaryId?: string | null,
  fallbackType?: string,
  fallbackId?: string
): ClosePageAction {
  const route =
    primaryId && validId(primaryId)
      ? resolveURL(primaryType, primaryId)
      : resolveURL(fallbackType ?? primaryType, fallbackId ?? '');
  return {
    template: html` <a href="${route}" class="form-close-action float-end btn-close"></a>`,
    url: route
  };
}

export function flagInSet(flag: number, flagSet: number): boolean {
  return (flag & flagSet) !== 0;
}
