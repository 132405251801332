import { html, render } from 'lit';
import { AsyncConstructor } from '../../async-constructor';
import { showDevelopmentError } from '../../development-error';
import { PromiseTemplate } from './events';
import { InformationDispatcher } from './information-dispatcher';
import { IDispose } from '../dispose';

export class ViewBase implements AsyncConstructor, IDispose {
  _ui: HTMLDivElement | null = document.createElement('div') as HTMLDivElement;
  informationDispatcher: InformationDispatcher = new InformationDispatcher();
  get ui(): HTMLDivElement {
    if (!this._ui) this._ui = document.createElement('div') as HTMLDivElement;
    return this._ui;
  }
  constructor() {
    this.ui.addEventListener('ui-changed', this.eventChildUIChanged);
  }

  protected eventChildUIChanged = (_e: Event) => this.render();

  async dispatchInformation(info: string) {
    await this.informationDispatcher.setInformation(info);
  }
  eventPrefix() {
    return 'wm-event';
  }
  dispatchCustom<T>(name: string, values: T) {
    const options = {
      detail: values,
      bubbles: true,
      composed: true
    };
    const eventName = name.startsWith('!') ? name.substring(1) : `${this.eventPrefix()}-${name}`;
    this.ui.dispatchEvent(new CustomEvent(eventName, options));
    return values;
  }
  async afterConstruction(): Promise<void> {
    //Nothing to do
  }
  protected async template(): PromiseTemplate {
    return html``;
  }
  private _rendering = false;
  private _renderRequestCount = 0;
  public async render(): Promise<void> {
    this._renderRequestCount++;
    if (this._rendering) {
      // console.log('Unexpected Nested Or SideBySide Render Call');
      // push the render to the end of the queue
      // it is likely that the current render cycle is rendering stale data.
      setTimeout(() => this.internalRender(), 50);
      if (this._renderRequestCount > 10) showDevelopmentError('Recursive Render Cycle Error');
      return;
    }
    await this.internalRender();
  }
  private async internalRender() {
    this._rendering = true;
    try {
      render(await this.template(), this.ui);
      await this.postRender();
      this._renderRequestCount--;
    } finally {
      this._rendering = false;
    }
  }
  protected async postRender(): Promise<void> {
    //override if needed
  }
  dispatchUiChanged() {
    this.dispatchCustom('!ui-changed', { ui: this.ui });
  }
  async dispose() {
    this.ui.removeEventListener('ui-changed', this.eventChildUIChanged);
    this._ui?.remove();
    this._ui = null;
    //override if needed
  }
}
