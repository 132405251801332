//Unicode compatible converter to handle extended characters
function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

/*
//DEPRECATED  String.fromCodePoint(...bytes); will crash with a maximum stack exceeded on expanding ...bytes
//left here for knowledge purposes

function bytesToBase64(bytes) {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}
*/
function bytesToBase64v3(bytes: Uint8Array) {
  const binString = bytes.reduce(function (data, byte) {
    return data + String.fromCharCode(byte);
  }, '');

  return btoa(binString);
}

export function stringToBase64(data: string) {
  const binaryData = new TextEncoder().encode(data);
  return bytesToBase64v3(binaryData);
}

export function objectToBase64(data: any | null): string | null {
  if (!data) return null;
  try {
    const strData = JSON.stringify(data);
    const binaryData = new TextEncoder().encode(strData);
    return bytesToBase64v3(binaryData);
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export function base64ToObject<T>(base64: string | null | undefined): T | null {
  if (!base64) return null;
  const jsonData = new TextDecoder().decode(base64ToArrayBuffer(base64));
  return JSON.parse(jsonData) as T;
}

/**
 * Turns the contents of the given File into a base64 encoded string.
 * @param file The file to be converted.
 */
export function fileToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    //Assert the type to be string (as string). Even though the result of the reader can be string | ArrayBuffer | null
    // we are using readAsDataURL method, which returns a base64 encoded string
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
}
