export function diff_minutes(then: Date, now: Date) {
  let diff = (then.getTime() - now.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function lapsedMinutes(then: Date): number {
  return diff_minutes(then, new Date());
}

export interface ITimer {
  stop(msg?: string);
}
export class DebugTimer implements ITimer {
  time: number = Date.now();
  endTime?: number;
  constructor() {
    this.start();
  }

  get milliseconds() {
    return (this.endTime ?? Date.now()) - this.time; // milliseconds elapsed since start
  }
  stop(msg?: string) {
    this.endTime = Date.now();
    if (msg) {
      console.log(`${this.milliseconds}ms -> ${msg}`);
    }
  }
  start() {
    this.time = Date.now();
    this.endTime = undefined;
  }
}

export function startTimer(): ITimer {
  return new DebugTimer();
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
