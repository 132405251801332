import { strToMoney } from '../../currency-formatter';
import { FormInputMoneyView } from '../../FormInputView';

let internalIdSequencer = 100;

export type EventDataBindingFieldName = (input: string, internalId: string) => string;

export function getInternalId() {
  return (internalIdSequencer++).toString();
}
/**
 * Databinding is a means of reading and writing data into html elements
 */
export class DataBinding {
  parent: HTMLElement;
  internalId: string;
  allowMissingElements = false;
  fieldGenerator?: EventDataBindingFieldName;
  constructor(parent: HTMLElement, internalId?: string | null, fieldGenerator?: EventDataBindingFieldName) {
    this.parent = parent;
    this.fieldGenerator = fieldGenerator;
    this.internalId = internalId ?? getInternalId();
  }

  public field(fieldName: string): string {
    fieldName = fieldName.trim();
    return this.fieldGenerator ? this.fieldGenerator(fieldName, this.internalId) : `${fieldName}-${this.internalId}`;
  }

  public readonly(fieldName: string): boolean {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);
    if (!element) {
      element = this.parent.querySelector(`input[name="${internalFieldName}"]:checked`);

      if (!element) {
        if (this.allowMissingElements) return true;
        throw new Error(`#${internalFieldName} not found`);
      }
    }
    return element['readOnly'] || element['disabled'];
  }

  public getValue(fieldName: string): string {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`input[name="${internalFieldName}"]:checked`);

      if (!element) {
        if (this.allowMissingElements) return '';
        throw new Error(`#${internalFieldName} not found`);
      }
    }

    let value;

    if (element instanceof HTMLInputElement && element.getAttribute('type') == 'checkbox')
      value = element.checked.toString();
    if (element instanceof HTMLInputElement && element.hasAttribute('data-picker')) value = element.dataset['value'];
    else value = element['value'] ?? '';

    //sometimes a text-area that is not set, just has no value property yet.
    //we will just let QA and testing find the issues and correct here as needed.

    //if (!value) throw new Error(`#${internalFieldName} does not have a value attribute`);
    return value;
  }

  public getInt(fieldName: string, allowNull?: boolean, float?: boolean): number | null {
    const strValue = this.getValue(fieldName).trim();
    if (!strValue || (strValue === '' && allowNull)) return null;

    const value = float ? parseFloat(strValue) : parseInt(strValue);
    const internalFieldName = this.field(fieldName);
    if (isNaN(value)) throw new Error(`#${internalFieldName} is not a number "${strValue}"`);
    return value;
  }

  public getFloat(fieldName: string, allowNull?: boolean): number | null {
    return this.getInt(fieldName, allowNull, true);
  }
  public getMoney(fieldName: string, allowNull?: boolean): number | null {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`) as any;
    const moneyElement = $(element).closest('bs-form-money')[0] as FormInputMoneyView;
    const moneyVal = strToMoney(moneyElement?.value ?? '0', 4);
    if (!isNaN(moneyVal)) return moneyVal;
    else if (allowNull) return null;
    else return 0;
  }

  public getBoolean(fieldName: string): boolean {
    const strValue = this.getValue(fieldName);
    return strValue === 'true';
  }

  //TODO > This needs to be removed: GetValue should return the value
  /**
   * Gets the contents of a file that has been uploaded to an element with the given field name.
   * @param fieldName The field name of the element.
   * @returns The contents of the file, undefined (unable to get contents) or null (no file uploaded).
   */
  public getFiles(fieldName: string): FileList | null {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`) as HTMLInputElement;
    if (!element) throw new Error(`#${internalFieldName} not found`);

    return element.files;
  }

  public getFile(fieldName: string, fileIndex: number): File | null {
    const files = this.getFiles(fieldName);
    if (files) {
      return files.item(fileIndex);
    }
    return null;
  }

  //TODO> This needs to be removed. Set Value should clear the value
  public removeFiles(fieldName: string): void {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`) as HTMLInputElement;
    if (!element) throw new Error(`#${internalFieldName} not found`);
    // make sure that the element has files before clearing, otherwise we could clear something else by mistake.
    if (element.files) {
      element.value = '';
    }
  }

  public setValue(fieldName: string, value: string | null) {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`input[name="${internalFieldName}"][value="${value}"]`);

      if (!element) {
        if (this.allowMissingElements) return;
        throw new Error(`#${internalFieldName} not found`);
      }
    }

    if (element instanceof HTMLInputElement && element.getAttribute('type') == 'checkbox')
      element.checked = value === 'true';
    if (element instanceof HTMLInputElement && element.hasAttribute('data-picker'))
      element.dataset['value'] = value ?? undefined;
    else if (element instanceof HTMLInputElement && element.getAttribute('type') == 'radio') element.checked = true;
    else element['value'] = value;
  }
}
