import { TemplateResult } from 'lit-html';
import { showError } from '../../components/ui/show-error';
import { modalScreenFactoryImpl } from '../../components/ui/modal-factory';
import { lockUIandExecute } from '../../ui-lock';

export interface LoginConfig {
  dateCreated: string;
  id: number;
  recordVersion: string;
}

export interface BaseLogin {
  dateCreated: string;
  id: number;
  recordVersion: string;
  systemUserId: string;
  userName: string;
  friendlyName: string;
  emailAddress: string;
  phoneNumber: string;
  jobTitle: string;
  loginConfigId: number | null;
  loginConfig: LoginConfig | null;
  claims: { [key: string]: string };
}
export interface ViewBaseLogin {
  tenantId: number;
  baseLogin: BaseLogin;
  roles: string[];
  deploymentClaims: { [key: string]: string };
  active: boolean;
}

export interface ILoginClaimManager {
  id: number;
  hasClaim: (claimName: string) => boolean;
  getClaim: (claimName: string) => string | undefined;
  setClaim: (claimName: string, value: string) => void;
}

export interface ILicenseServerApiCredentials {
  host: string;
  token: string;
}

export interface BaseLoginModalEditProperties {
  propertyId: number;
  label: string;
  inputId: string;
  visible: boolean;
  editControl: (claimManager: ILoginClaimManager) => TemplateResult;
  save: (claimManager: ILoginClaimManager) => void;
}

export interface BaseLoginTableColumnProperties {
  title: string;
  displayValue: (login: ViewBaseLogin) => TemplateResult;
}

export interface ILicenseServerUIStaffPageProvider {
  credentials: ILicenseServerApiCredentials;
  onBeforeSave?: (login: ViewBaseLogin) => Promise<boolean>;
  onAfterSave?: (login: ViewBaseLogin) => Promise<void>;
  getModalProperties?: () => BaseLoginModalEditProperties[];
  getColumnProperties?: () => BaseLoginTableColumnProperties[];
  uiChanged?: () => void;
  userFilter?: (login: ViewBaseLogin) => boolean;
  canEditUser?: (login: ViewBaseLogin) => boolean;
  includeBranchManager?: boolean;
}
export interface ILicenseServerUICynclySupportPageProvider {
  credentials: ILicenseServerApiCredentials;
}
export interface ICommandAction {
  label: string;
  event: () => void;
  enabled: boolean;
}
export interface IStaffPage {
  template: () => TemplateResult;
  actions: () => ICommandAction[];
}
export interface ICynclySupportPage {
  template: () => TemplateResult;
  actions: () => ICommandAction[];
}
export interface ILicenseServerUIBuilder {
  getStaffPage: (provider: ILicenseServerUIStaffPageProvider) => IStaffPage;
  getCynclyAccessPage: (provider: ILicenseServerUICynclySupportPageProvider) => ICynclySupportPage;
}
// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __localdebug__: string;

export function licenseServerUIBootLoader(licenseServerHost?: string): Promise<ILicenseServerUIBuilder | undefined> {
  if (globalThis.webmoduleLicenseServerUIServices)
    return Promise.resolve(globalThis.webmoduleLicenseServerUIServices.builder);
  if (!licenseServerHost) licenseServerHost = globalThis.dealerConfiguration.licenseServerHost;

  const usedebug = __localdebug__;
  const isDebugVersion = usedebug == 'true';

  const source = isDebugVersion
    ? `${licenseServerHost}/js/uiservices/softtech-license-server.dev.js`
    : `${licenseServerHost}/js/uiservices/softtech-license-server.js`;

  const licenseServerUiScript = document.createElement('script');
  let completed: string | undefined;
  licenseServerUiScript.onload = async () => {
    const lsUiEvents = globalThis.webmoduleLicenseServerUIServices?.events;
    if (!lsUiEvents) {
      console.log(`License Server UI did not load correctly`);
      globalThis.webmoduleLicenseServerUIServices = undefined;
      completed = 'false';
      return;
    }
    lsUiEvents.setErrorDialogEventHandler(showError);
    //lang is managed globally now
    //lsUiEvents.setLangHandler(tlang, lang);
    lsUiEvents.setModalScreenFactory(modalScreenFactoryImpl);
    lsUiEvents.setUILockAndExecuteEvent(lockUIandExecute);
    completed = 'true';
  };
  licenseServerUiScript.setAttribute('src', source);
  document.body.appendChild(licenseServerUiScript);

  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (completed) {
        clearInterval(interval);
        resolve(completed === 'true' ? globalThis.webmoduleLicenseServerUIServices.builder ?? undefined : undefined);
      }
    }, 100);
  });
}
