//helper class that works with objects, editors and
//databinding to form a very simple observation pattern
//SEE: quote-detail-view.ts for an example

import { DevelopmentError } from '../../../development-error';
import { strToMoney } from '../../currency-formatter';
import {
  serverDateTimeToLocalRFC3339,
  serverDateToLocalRFC3339,
  localRFC3339DateTimeToServer,
  localRFC3339DateToServer,
  localDateTimeToServer
} from '../../datetime-converter';
import { DataBinding } from './databinding';

export interface ValueBinder {
  fieldType: FieldType;
  nullable: boolean;

  getValue(): string | number | boolean | null;

  setValue(value: string | number | boolean | null): void;

  readonly(): boolean;
}

export type EventValue = string | number | boolean | null;
export type EventValueGetter = () => EventValue;
export type EventValueSetter = (value: EventValue, fieldType: FieldType, nullable: boolean) => void;

export class DynamicValueBinder implements ValueBinder {
  fieldType: FieldType;
  nullable: boolean;
  getter: EventValueGetter;
  setter: EventValueSetter;
  _readonly: (() => boolean) | undefined;

  constructor(
    fieldType: FieldType,
    nullable: boolean,
    getter: EventValueGetter,
    setter: EventValueSetter,
    readonly?: () => boolean
  ) {
    this.nullable = nullable;
    this.fieldType = fieldType;
    this.getter = getter;
    this.setter = setter;
    this._readonly = readonly;
  }

  getValue(): string | number | boolean | null {
    return this.getter();
  }

  setValue(value: string | number | null) {
    this.setter(value, this.fieldType, this.nullable);
  }

  readonly(): boolean {
    return this._readonly?.() ?? false;
  }
}

export enum FieldType {
  string = 0,
  int = 1,
  dateTime = 2,
  float = 3,
  boolean = 4,
  money = 5,
  date = 7
}

export class ObjectPropertyBinder implements ValueBinder {
  fieldBinder: DynamicValueBinder;
  data: () => any;
  fieldName: string;

  constructor(data: () => any, fieldName: string, fieldType: FieldType, nullable: boolean) {
    this.data = data;
    this.fieldName = fieldName;
    this.fieldBinder = new DynamicValueBinder(
      fieldType,
      nullable,
      () => {
        return this.data()[this.fieldName];
      },
      (value: EventValue) => {
        const newValue = value?.toString();
        let val = 0;
        let dateStr = '';

        if ((value === null || value === undefined || value === '') && nullable) this.data()[this.fieldName] = null;
        else
          switch (fieldType) {
            case FieldType.string:
              this.data()[this.fieldName] = newValue ?? '';
              break;
            case FieldType.int:
              if (!newValue) throw new Error(`${newValue} is not a number`);
              val = parseInt(newValue);
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.float:
              if (!newValue) throw new Error(`${newValue} is not a number`);
              val = parseFloat(newValue);
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.money:
              if (!newValue) throw new Error(`${newValue} is not a number`);
              val = strToMoney(newValue, 4);
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.boolean:
              this.data()[this.fieldName] = !!(newValue && newValue === 'true');
              break;
            case FieldType.dateTime:
              //TODO untested
              if (!newValue) throw new DevelopmentError(`invalid date "${newValue}" `);

              //C# streaming is forced now to match toISOString exactly
              dateStr = localRFC3339DateTimeToServer(newValue);
              this.data()[this.fieldName] = dateStr;
              break;
            case FieldType.date:
              if (!newValue) throw new DevelopmentError(`invalid date "${newValue}" `);

              // If you use the ISO format, and you give only the date and not the time/time zone,
              // it will automatically accept the time zone as UTC. But if you add the time/timezone it will
              // accept the time zone as local(or specifically specified zone).
              // In our case newValue is the already local date (yyyy-mm-dd), just passing that to the
              // date constructor will create a date object with the values but as UTC that will result in
              // the date being incremented with every save.
              // By adding a time to the newValue (yyyy-mm-dd) + "T00:00", the date is created as local date
              // time zone which then gets "converted" back to the correct UTC value with the toISOString.
              dateStr = localRFC3339DateToServer(newValue);
              this.data()[this.fieldName] = dateStr;
              break;
          }
      },
      undefined // properties are not readonly
    );
  }

  get fieldType(): FieldType {
    return this.fieldBinder.fieldType;
  }

  get nullable(): boolean {
    return this.fieldBinder.nullable;
  }

  getValue(): string | number | boolean | null {
    return this.fieldBinder.getValue();
  }

  setValue(value: string | number | null) {
    this.fieldBinder.setValue(value);
  }

  readonly(): boolean {
    return false;
  }
}

export function htmlDisplayValue(
  value: string | number | boolean | null,
  fieldType: FieldType,
  nullable: boolean
): string | null {
  const newValue = value?.toString();

  let val = 0;
  let dateVal = '';
  if ((value === null || value === undefined || value === '') && nullable) return null;
  else
    switch (fieldType) {
      case FieldType.string:
        return newValue ?? '';
      case FieldType.int:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = parseInt(newValue);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.float:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = parseFloat(newValue);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.money:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = strToMoney(newValue, 4);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.boolean:
        return newValue ?? 'false';
      case FieldType.dateTime:
        //TODO untested
        if (!newValue) throw Error('invalid datetime');

        //temporary hack for some local objects declared as
        //date but need to be strings
        if (typeof newValue !== 'string') dateVal = localDateTimeToServer(newValue as any as Date);
        else dateVal = newValue;
        return serverDateTimeToLocalRFC3339(dateVal);
      case FieldType.date:
        if (!newValue) throw Error('invalid date');
        //temporary hack for some local objects declared as
        //date but need to be strings
        if (typeof newValue !== 'string') dateVal = localDateTimeToServer(newValue as any as Date);
        else dateVal = newValue;
        return serverDateToLocalRFC3339(dateVal);
    }
}

export class HTMLElementBinder implements ValueBinder {
  fieldBinder: DynamicValueBinder;
  binder: DataBinding;

  fieldName: string;

  constructor(dataBinder: DataBinding, fieldName: string, fieldType: FieldType, nullable: boolean) {
    this.binder = dataBinder;
    this.fieldName = fieldName;
    this.fieldBinder = new DynamicValueBinder(
      fieldType,
      nullable,
      () => {
        switch (fieldType) {
          case FieldType.int:
            return this.binder.getInt(fieldName, nullable);
          case FieldType.float:
            return this.binder.getFloat(fieldName, nullable);
          case FieldType.money:
            return this.binder.getMoney(fieldName, nullable);
          case FieldType.boolean:
            return this.binder.getBoolean(fieldName);
          case FieldType.string:
            return this.binder.getValue(fieldName);
          case FieldType.dateTime:
          case FieldType.date:
            return this.binder.getValue(fieldName);
          default:
            throw new Error(`${fieldType} not mapped yet`);
        }
      },
      (value: EventValue, fieldType: FieldType, nullable: boolean) => {
        const newValue = htmlDisplayValue(value, fieldType, nullable);
        this.binder.setValue(fieldName, newValue);
      },
      () => this.binder.readonly(fieldName)
    );
  }

  get fieldType(): FieldType {
    return this.fieldBinder.fieldType;
  }

  get nullable(): boolean {
    return this.fieldBinder.nullable;
  }

  getValue(): string | number | boolean | null {
    return this.fieldBinder.getValue();
  }

  setValue(value: string | number | null) {
    this.fieldBinder.setValue(value);
  }

  readonly(): boolean {
    return this.fieldBinder.readonly();
  }
}

export interface ValueManager {
  modified: boolean;

  applyChangeToValue(): void;

  resetEditorValue(): void;
}

export class ValueEditorBinder implements ValueManager {
  dataField: string;
  valueBinder: ValueBinder;
  editorBinder: ValueBinder;

  constructor(dataField: string, valueBinder: ValueBinder, editorBinder: ValueBinder) {
    this.editorBinder = editorBinder;
    this.valueBinder = valueBinder;
    this.dataField = dataField;
  }

  get modified(): boolean {
    const editorVal = this.editorBinder.getValue();
    const objectVal = this.valueBinder.getValue();
    return editorVal !== objectVal;
  }

  applyChangeToValue() {
    if (this.editorBinder.readonly()) return;
    const editorVal = this.editorBinder.getValue();
    this.valueBinder.setValue(editorVal);
  }

  resetEditorValue() {
    const objVal = this.valueBinder.getValue();
    this.editorBinder.setValue(objVal);
  }
}

export class DataTracker implements ValueManager {
  binder: DataBinding;
  bindings: ValueEditorBinder[] = [];

  constructor(binder: DataBinding) {
    this.binder = binder;
  }

  get modified(): boolean {
    return this.bindings.some(x => x.modified);
  }

  add(valueManager: ValueEditorBinder) {
    this.bindings.push(valueManager);
  }

  applyChangeToValue() {
    this.bindings.forEach(x => x.applyChangeToValue());
  }

  resetEditorValue() {
    this.bindings.forEach(x => x.resetEditorValue());
  }

  addObjectBinding(
    data: () => any,
    dataField: string,
    editorFieldName: string,
    fieldType: FieldType = FieldType.string,
    nullable = false
  ) {
    this.bindings.push(
      new ValueEditorBinder(
        dataField,
        new ObjectPropertyBinder(data, dataField, fieldType, nullable),
        new HTMLElementBinder(this.binder, editorFieldName, fieldType, nullable)
      )
    );
  }

  addBinding(
    binding: ValueBinder,
    dataField: string,
    editorFieldName: string,
    fieldType: FieldType = FieldType.string,
    nullable = false
  ) {
    this.bindings.push(
      new ValueEditorBinder(
        dataField,
        binding,
        new HTMLElementBinder(this.binder, editorFieldName, fieldType, nullable)
      )
    );
  }

  removeBinding(dataField:string) {
    const i = this.bindings.findIndex(x => x.dataField === dataField);

    if (i > -1) {
      this.bindings.splice(i, 1);
    }
  }

  getObjectValue(fieldName: string): EventValue {
    const binder = this.bindings.find(x => x.dataField === fieldName);
    return binder?.valueBinder.getValue() ?? null;
  }
  getEditorValue(fieldName: string): EventValue {
    const binder = this.bindings.find(x => x.dataField === fieldName);
    return binder?.editorBinder.getValue() ?? null;
  }
  setEditorValue(fieldName: string, value: EventValue) {
    const binder = this.bindings.find(x => x.dataField === fieldName);
    binder?.editorBinder.setValue(value);
  }
  getObjectDisplayValue(fieldName: string): string | null {
    const binder = this.bindings.find(x => x.dataField === fieldName);
    if (binder)
      return htmlDisplayValue(binder.valueBinder.getValue(), binder.valueBinder.fieldType, binder.valueBinder.nullable);
    else return '';
  }
}
