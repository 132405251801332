import { LitElement } from 'lit';

export class LitElementBase extends LitElement {
  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr == undefined || attr !== 'false';
    }
  }
  eventPrefix() {
    return 'wm-event';
  }
  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    this.dispatchEvent(new CustomEvent(`${this.eventPrefix()}-${name}`, options));
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
