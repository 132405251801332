import { getApi, getApiFactory } from '../../api/api-injector';
import { MultiPromise } from '../../multicast-promise';
import { DevelopmentError } from '../../development-error';
import { getCurrentUser, setCurrentUser, setUserClaims } from '@softtech/webmodule-components';
import {
  GlobalSupplierReference,
  ResultGetAllFranchiseeDetails
} from '../../api/supplier-api-interface-franchiseenetwork';
import { NullPromise } from '@softtech/webmodule-data-contracts';

import { flushCache } from '../cache/cache-registry';
import { SupplierSettingsManager } from './supplier-settings-manager';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';

export class CurrentUserDataStore {
  private static instance: CurrentUserDataStore;

  private constructor() { }

  public static getInstance(): CurrentUserDataStore {
    if (!CurrentUserDataStore.instance) {
      CurrentUserDataStore.instance = new CurrentUserDataStore();
    }
    return CurrentUserDataStore.instance;
  }

  loaded = false;

  private _allFranchiseeDetails: ResultGetAllFranchiseeDetails | null = null;

  private _globalSupplier: GlobalSupplierReference | null = null;

  public supplierSettingsManager: SupplierSettingsManager = new SupplierSettingsManager();

  public get allFranchiseeDetails(): ResultGetAllFranchiseeDetails {
    this.checkLoaded();
    if (!this._allFranchiseeDetails) throw new DevelopmentError('Franchisee Summary is null');
    return this._allFranchiseeDetails;
  }

  public get globalSupplier(): GlobalSupplierReference {
    this.checkLoaded();
    if (!this._globalSupplier) throw new DevelopmentError('GlobalSupplierReference is null');
    return this._globalSupplier;
  }

  public get supplierSettings(): SupplierSettings {
    this.checkLoaded();
    this.supplierSettingsManager.checkLoaded();
    return this.supplierSettingsManager.supplierSettings!;
  }

  /**
   * This should only be called from LogOFF
   */
  clear() {
    this.loaded = false;
    this._allFranchiseeDetails = null;
    this._globalSupplier = null;
    this.supplierSettingsManager.clear();
    this.loadCorePromise.reset();
    this.loadCoreDetailsPromise.reset();
    flushCache();
  }
  /**
   * This should called so the next await gets new data, without destroying in use data
   */
  requestRefresh() {
    this.loadCorePromise.reset();
    this.loadCoreDetailsPromise.reset();
  }

  /**
   * forces the cache to refresh
   * @returns a fresh copy of the franchisee
   */
  async reLoadGlobalSupplier(): NullPromise<GlobalSupplierReference> {
    this.requestRefresh();
    await this.loadCoreDetails();
    return this.globalSupplier;
  }

  private loadCoreDetailsPromise = new MultiPromise<void>(async () => {
    if (!getCurrentUser()) {
      const result = await getApi().performAuthenticationProcess();
      if (!result) {
        window.location.href = '/';
      }
      return;
    } else {
      try {
        await this.loadCorePromise.run();
      } catch (e) {
        alert((e as any).message);
        setTimeout(() => setCurrentUser(null), 500);
      }
    }
  }, false);

  private loadCorePromise = new MultiPromise<void>(async () => await this.performLoadCoreDetails(), false);

  async loadCoreDetailsAfterLogin() {
    await this.loadCorePromise.run();
  }

  async loadCoreDetails() {
    await this.loadCoreDetailsPromise.run();
  }

  private checkLoaded() {
    if (!this.loaded) throw new Error('CurrentUserDataStore has not been loaded');
  }

  private async performLoadCoreDetails() {
    const user = getCurrentUser();
    if (!user) throw new DevelopmentError('Authentication could not be performed');

    const api = getApiFactory().franchisee();

    const result = await api.getActiveUserInformation({
      systemUserId: user.id
    });
    if (result) {
      this._globalSupplier = result.globalSupplier;
      setUserClaims(result.userClaims);
    } else throw new Error('could not load user details');

    if (!(await this.supplierSettingsManager.needsSettings())) throw new Error('Could not load supplier settings');

    this._allFranchiseeDetails = await api.getAllFranchiseeDetails();
    this.loaded = true;
    this.checkLoaded();
  }
  async reloadFranchiseeDetails() {
    this._allFranchiseeDetails = await getApiFactory().franchisee().getAllFranchiseeDetails();
  }
}

export const userDataStore = CurrentUserDataStore.getInstance();
