import { tlang } from '@softtech/webmodule-components';
import { AppHome } from './pages/app-home';
// import { AppSettings } from './pages/app-settings';
import { PageNotFound } from './pages/page-not-found';
import { AppDealers } from './pages/app-dealer';
import { AppSettings } from './pages/app-settings';
import { AppLogin } from './pages/app-login';
import { AppSupportInbox } from './pages/app-support-inbox';
import { pages } from './page-constants';

export function appConfigMenuPageItems() {
  return [
    {
      name: pages.home,
      title: tlang`Dashboard`
    },
    {
      name: pages.supportInbox,
      title: tlang`Support Inbox`
    },
    {
      name: pages.dealers,
      title: tlang`%%franchisee-network%%`
    },
    {
      name: pages.settings,
      title: tlang`Settings`
    }
  ];
}
export function appConfig() {
  return {
    routes: [
      {
        path: '/',
        name: pages.home,
        component: 'app-home',
        action: async () => {
          //return home
          return new AppHome();
        }
      },
      {
        path: '/login',
        name: pages.login,
        component: 'app-login',
        action: async () => {
          return new AppLogin();
        }
      },
      {
        path: '/dealers',
        name: pages.dealers,
        action: async () => {
          return new AppDealers();
        }
      },
      {
        path: '/supportinbox',
        name: pages.supportInbox,
        action: async () => {
          return new AppSupportInbox();
        }
      },
      {
        path: '/settings',
        name: pages.settings,
        action: async () => {
          return new AppSettings();
        }
      },
      {
        path: '(.*)',
        name: 'not-found',
        action: async () => {
          return new PageNotFound();
        }
      }
    ]
  };
}
