import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export class LitElementBase extends LitElement {
  protected onReadonlyChanged() {
    //
  }
  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr == undefined || attr !== 'false';
    }
  }
  @property()
  private _readonly = false;

  public get readonly() {
    return this._readonly;
  }

  public set readonly(value) {
    if (value !== this._readonly) {
      this._readonly = value;
      this.onReadonlyChanged?.();
    }
  }
  protected get isReadonly(): boolean {
    return this.readonly || this.attributeTrue('readonly');
  }
  eventPrefix() {
    return 'wm-event';
  }
  dispatchCustom<T>(name: string, values: T) {
    const options = {
      detail: values,
      bubbles: true,
      composed: true
    };
    const eventName = name.startsWith('!') ? name.substring(1) : `${this.eventPrefix()}-${name}`;
    this.dispatchEvent(new CustomEvent(eventName, options));
    return values;
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
