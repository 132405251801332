import { TemplateResult, html } from 'lit';
import { Tooltip } from 'bootstrap';

export function getToolTip(toolTip: string): TemplateResult {
  return html` <span
    class=""
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-html="true"
    data-bs-title="${toolTip}"
  >
    <i class="fa-regular fa-circle-question fa-lg"></i>
  </span>`;
}

export function registerToolTips(): void {
  var tooltipElements = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipElements.map(function (tooltipElement) {
    return new Tooltip(tooltipElement);
  });
}
