import { PromiseTemplate, Snippet } from '@softtech/webmodule-data-contracts';
import {
  SupportInboxItemStatus,
  SupportInboxItemType,
  SupportInboxTicketOwnerFilter,
  ViewSupportInboxItemEx
} from '../../api/supplier-api-interface-franchiseenetwork';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { TemplateResult, html } from 'lit';
import { tlang } from '@softtech/webmodule-components';
import { WebModuleQuoteSupportInboxView } from './quote-support-inbox-view';
import { RefreshCommandAction, commandActionButton, delayedEvent } from '../../components/ui/command-action';
import { TimedTrigger } from '../../timed-trigger';

export interface QuoteSupportInboxViewOptions {
  tabName: string;
  caption: Snippet;
  typeFilter: SupportInboxItemType;
  statusFilter: SupportInboxItemStatus;
  eventLogin: (item: ViewSupportInboxItemEx) => Promise<void>;
  showResolvedColumns?: boolean;
  showLastModified?: boolean;
  showAssignedTo?: boolean;
}
export class QuoteSupportInboxViewTab extends PageControlTabWithIndependantSaving {
  options: QuoteSupportInboxViewOptions;
  constructor(options: QuoteSupportInboxViewOptions) {
    super();
    this.options = options;
  }
  private _ticketOwner: SupportInboxTicketOwnerFilter = SupportInboxTicketOwnerFilter.All;
  public get ticketOwner(): SupportInboxTicketOwnerFilter {
    return this._ticketOwner;
  }
  public set ticketOwner(value: SupportInboxTicketOwnerFilter) {
    if (this._ticketOwner !== value) {
      this._ticketOwner = value;
      this.render();
    }
  }

  private _filter = '';
  public get filter() {
    return this._filter;
  }
  public set filter(value) {
    if (this._filter !== value) {
      this._filter = value;
      this.render();
    }
  }
  timedTrigger?: TimedTrigger;

  public async afterConstruction(): Promise<void> {
    super.afterConstruction();
    const triggerEvent = e => {
      const searchTerm = (e.target as HTMLInputElement).value;

      this.filter = searchTerm;
    };
    this.timedTrigger = new TimedTrigger(1500, triggerEvent);
  }
  public filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger?.getResetEvent();
    const triggerEvent = this.timedTrigger?.getTriggerEarlyEvent();

    return html`
      <label for="filterText">${tlang`Filter:`}</label>
      <input
        type="text"
        id="filterText"
        placeholder="Text Search"
        @oninput=${resetEvent}
        @blur=${triggerEvent}
        @keyup=${resetEvent}
      />
    `;
  }
  protected getCaption(): Snippet {
    return this.options.caption;
  }
  public async allowPageSwitch(): Promise<boolean> {
    return true;
  }
  public allowDeletePage(): boolean {
    return false;
  }

  private async refreshTableData() {
    const inbox = this.ui.querySelector('.inbox-view') as WebModuleQuoteSupportInboxView;
    if (inbox) inbox.refreshData();
  }
  async onEnter(): Promise<void> {
    this.refreshAction.event();
    await this.render();
  }

  private refreshAction = new RefreshCommandAction(delayedEvent(() => this.refreshTableData(), 2000));
  buttonMenu() {
    return html` ${commandActionButton(this.refreshAction)}`;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const checkTemplate = (label: string, value: SupportInboxTicketOwnerFilter) => {
      const eventClick = e => {
        e.stopImmediatePropagation();
        this.ticketOwner = value;
      };
      if (value === this.ticketOwner)
        return html`<span @click=${eventClick} class="ticket-owner-check">
          <i class="fa fa-solid fa-circle-dot"></i>
          ${label}
        </span>`;
      else
        return html`<span @click=${eventClick} class="ticket-owner-check">
          <i class="fa fa-regular fa-circle"></i>
          ${label}
        </span>`;
    };
    const eventQuoteReview = async (e: CustomEvent<{ item: ViewSupportInboxItemEx }>) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      this.options.eventLogin(e.detail.item);
    };
    return html`<div class="support-inbox-header">
        <div class="filter-left">
          ${checkTemplate(tlang`All Tickets`, SupportInboxTicketOwnerFilter.All)}
          ${checkTemplate(tlang`My Tickets`, SupportInboxTicketOwnerFilter.Mine)}
          ${checkTemplate(tlang`Unassigned Tickets`, SupportInboxTicketOwnerFilter.Unassigned)}
        </div>
        <div class="filter-right">
          <div class="filter-text">${this.filterTemplate()}</div>
        </div>
      </div>
      <webmodule-quote-support-inbox-view
        class="inbox-view"
        .ticketOwnerFilter=${this.ticketOwner}
        .typeFilter=${this.options.typeFilter}
        .statusFilter=${this.options.statusFilter}
        .filter=${this.filter}
        .showAssignedTo=${this.options.showAssignedTo}
        .showLastModified=${this.options.showLastModified}
        .showResolvedColumns=${this.options.showResolvedColumns}
        @wm-supportinbox-quotereview=${eventQuoteReview}
      ></webmodule-quote-support-inbox-view>`;
  }
}
