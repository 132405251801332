import { getCurrentUser } from '@softtech/webmodule-components';
import { emptyGuid, newGuid } from '../../api/guid';
import { validId } from '../../components/ui/helper-functions';

const sessionLockKey = 'dealer:sessionlock';
export function getSessionLock(): string {
  let lock = sessionStorage.getItem(sessionLockKey) ?? '';
  if (!validId(lock)) {
    lock = newGuid();
    sessionStorage.setItem(sessionLockKey, lock);
  }
  return lock;
}

function getMachineLock(): string {
  let lock = localStorage.getItem(sessionLockKey) ?? '';
  if (!validId(lock)) {
    lock = newGuid();
    localStorage.setItem(sessionLockKey, lock);
  }
  return lock;
}

export function getUserLock(): string {
  const user = getCurrentUser();
  //const lock = `${getMachineLock()}:${getSessionLock()}:${user?.tenantId ?? 0}:${user?.id ?? emptyGuid}`;
  const lock = `${getMachineLock()}:${getSessionLock()}:${user?.tenantId ?? 0}:${user?.id ?? emptyGuid}`;
  return lock;
}
