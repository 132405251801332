import { Snippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { TemplateResult, html } from 'lit';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { lockUIandExecute } from '../../ui-lock';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { Territory, TerritoryFranchiseeBranchLink } from '../../api/supplier-api-interface-supplier';
import { showValidations } from '../../components/ui/modal-validationhandler';
import { equalsIgnoringCase } from '../data/settings-helpers';
import { TerritoryContainerManager, TerritoryContainerManagerOptions } from '../data/territory-manager';
import TerritoryRegionsTableElement from '../ui/territory-regions-table-element';
import { ManageTerritoryModal } from './region-edit-modal';
import { ICommandAction } from '../../components/ui/command-action';
import { AskConfirmation, ConfirmationButtonType, confirmationButtons } from '../../components/ui/modal-confirmation';

export type TerritoryEvent = (territory: Territory) => Promise<void>;

export interface ITerritoryRegionTable {
  template: () => TemplateResult;
  actions: () => ICommandAction[];
  refresh: () => void;
}

export interface ITerritoryFranchiseeTable extends ITerritoryRegionTable {
  setTerritory: (territory: TerritoryFranchiseeBranchLink[]) => void;
}

export class SettingsRegionView extends PageControlTabWithIndependantSaving {
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  protected regionTable: ITerritoryRegionTable;
  protected selectedTerritory: Territory | null = null;

  protected territoryManager: TerritoryContainerManager;

  private newTerritoryName: string = '';

  constructor() {
    super();
    this.pageFragment = 'regions';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.territoryManager = this.territoryManagerFactory();

    this.regionTable = this.regionTableFactory();

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('newTerritoryName', FieldType.string, true);
  }

  public get territories(): Territory[] {
    return this.territoryManager.territories;
  }

  public async afterConstruction(): Promise<void> {
    await this.territoryManager.needsTerritories();
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Groups: !!territory-area-region!!`;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];
    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }
  }
  public internalDataChanged(): boolean {
    return this.territoryManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  protected async internalSaveData(): Promise<boolean> {
    return false;
  }

  protected async refreshData() {
    await this.render();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const createNewTerritoryEvent = async () => lockUIandExecute(async () => await this.createNewTerritory());

    return html` <div>
      <form id="TerritoryRegionForm" class="form-two-col">
        <div class="row">
          <div class="col-sm-6 form-column">
            <h2>${this.getCaption()}</h2>
            ${this.regionTable.template()}
            <div class="new-table-item">
              ${forms.text('newTerritoryName', tlang`Group Name`, 100)}
              <button
                @click=${createNewTerritoryEvent}
                class="btn btn-primary shortstop-edit"
                type="button"
                id=${forms.id('create-client')}
              >
                ${tlang`Add Group`}
              </button>
            </div>
          </div>
          <div class="col-sm-6 form-column">
            <h2>${tlang`!!franchisee!! & !!branch!!`}</h2>
            <webmodule-settings-regions-franchisee
              .territoryManager=${this.territoryManager}
              .selectedTerritory=${this.selectedTerritory}
            ></webmodule-settings-regions-franchisee>
          </div>
        </div>
      </form>
    </div>`;
  }

  protected regionTableFactory(): ITerritoryRegionTable {
    const table = new TerritoryRegionsTableElement();
    table.territoryManager = this.territoryManager;
    table.editEvent = async territory => {
      return await this.editTerritory(territory);
    };
    table.deleteEvent = async territory => {
      return await this.deleteTerritory(territory);
    };
    table.eventTerritoryClick = async linkData => {
      await this.selectTerritory(linkData);
    };

    return {
      template: () => html`${table}`,
      actions: () => table.getActions(),
      refresh: () => table.refreshData()
    };
  }

  protected async editTerritory(territory: Territory): Promise<void> {
    const modalOptions = {
      territory: territory,
      modalTitle: () => tlang`Edit %%territory%%`,
      saveButtonTitle: () => tlang`Save`,
      cancelButtonTitle: () => tlang`Cancel`,
      forceReadonly: false,
      territoryManager: this.territoryManager
    };
    const result = await lockUIandExecute(async () => {
      const modal = new ManageTerritoryModal(modalOptions);
      await modal.showModal();
      return modal.ok;
    });
    if (result) {
      this.regionTable.refresh();
    }
  }

  protected async selectTerritory(territory: Territory | null): Promise<void> {
    this.selectedTerritory = territory;
    this.render();
  }

  protected async deleteTerritory(territory: Territory | null): Promise<void> {
    if (territory) {
      const confirmDelete = await AskConfirmation(
        tlang`Are you sure you want to delete this %%territory%%?`,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        tlang`Delete %%territory%%`
      );

      if (confirmDelete) {
        await this.territoryManager.deleteTerritory(territory);
        if (this.selectedTerritory?.id == territory.id) {
          this.selectedTerritory = null;
        }
        this.regionTable.refresh();
        this.render();
      }
    }
  }

  protected territoryManagerFactory(): TerritoryContainerManager {
    const options: TerritoryContainerManagerOptions = {
      container: {
        territories: [],
        territoryFranchiseBranches: []
      }
    };
    return new TerritoryContainerManager(options);
  }

  protected async createNewTerritory(): Promise<void> {
    const newTerritoryName = this.dataTracker.getEditorValue('newTerritoryName')?.toString() ?? this.newTerritoryName;
    if (isEmptyOrSpace(newTerritoryName)) {
      await showValidations([tlang`No %%territory%% specified`]);
    } else {
      const hasExisting = this.territories.find((value: Territory) => equalsIgnoringCase(newTerritoryName, value.name));
      if (hasExisting) {
        await showValidations([tlang`A %%territory%% already exists with the name '${newTerritoryName}'`]);
      } else {
        const territory = await this.territoryManager.createTerritory(newTerritoryName);
        if (territory) {
          this.regionTable.refresh();
        }
      }
    }
    this.dataTracker.resetEditorValue();
    await this.render();
  }
}

globalThis.checkClosest = function (item: any, checked: boolean) {
  $(item).closest('.multi-actions').find('input').prop('checked', checked);
};
