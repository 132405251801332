// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { ModalDialog } from './modal-base';
import { tlang } from '@softtech/webmodule-components';
import { PromiseTemplate } from './events';
import { asMarkdownTemplate } from '../markdown';

export interface OptionButton {
  caption: string;
  value: string;
  btnClass?: string;
}

export function delayedInformation(info: string) {
  setTimeout(() => {
    information(info);
  }, 1000);
}

export async function information(message: string, title?: string) {
  await askOption(message, [{ caption: tlang`Ok`, value: '' }], title ?? tlang`Information`);
}

export async function askOption(message: string, buttons: OptionButton[], title?: string): Promise<string> {
  const modal: OptionDialog = new OptionDialog(message, buttons, title);
  await modal.showModal();
  return modal.result;
}

// noinspection HtmlUnknownAttribute
class OptionDialog extends ModalDialog {
  message: string;
  result = '';
  buttons: OptionButton[];
  title: string;

  constructor(message: string, buttons: OptionButton[], title?: string) {
    super();
    this.title = title ?? tlang`Confirm`;
    this.message = message;
    this.buttons = buttons;
  }

  protected async getTitle(): Promise<string> {
    return this.title;
  }

  footerTemplate(): TemplateResult {
    return html` ${this.buttons.map(b => this.buttonTemplate(b))} `;
  }
  buttonTemplate(b: OptionButton): TemplateResult {
    const event = () => {
      this.result = b.value;
      this.hideModal();
    };
    const btnClass = 'btn ' + (b.btnClass ?? 'btn-primary');
    return html`<button @click=${event} type="button" class=${btnClass}>${b.caption}</button>`;
  }

  async bodyTemplate(): PromiseTemplate {
    return html` ${asMarkdownTemplate(this.message)} `;
  }

  protected modalSize(): string {
    return 'modal-md';
  }
}
