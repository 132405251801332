import { ApiCommunications } from '@softtech/webmodule-data-contracts/dist/interfaces/api-communications';
import { NullPromise } from '@softtech/webmodule-data-contracts/dist/types/misc';
import { ApiBase } from './api-base';
import { BlobApi } from './blob-api';
import {
  InputCopyFilesByVirtualPath,
  InputDeleteFilesByVirtualPath,
  InputUpdateFileByVirtualPath,
  ResultCopyFilesByVirtualPath,
  ResultDeleteFilesByVirtualPath,
  ResultUpdateFileByVirtualPath
} from '../api/supplier-api-interface-blob';

export class BlobApiImpl extends ApiBase implements BlobApi {
  constructor(api: ApiCommunications) {
    super(api, 'api/SupplierFile/');
  }

  async copyFilesByVirtualPath(input: InputCopyFilesByVirtualPath): NullPromise<ResultCopyFilesByVirtualPath> {
    return await this.post<ResultCopyFilesByVirtualPath>('CopyFilesByVirtualPath', input);
  }

  async deleteFilesByVirtualPath(input: InputDeleteFilesByVirtualPath): NullPromise<ResultDeleteFilesByVirtualPath> {
    return await this.post<ResultDeleteFilesByVirtualPath>('DeleteFilesByVirtualPath', input);
  }

  async updateFileByVirtualPath(input: InputUpdateFileByVirtualPath): NullPromise<ResultUpdateFileByVirtualPath> {
    return await this.post<ResultUpdateFileByVirtualPath>('UpdateFileByVirtualPath', input);
  }

  fullUrl(path: string): string {
    return this.api.fullUrl(`${this.apiPath}${path}`);
  }
}
