// noinspection HtmlUnknownAttribute
import { customElement, query } from 'lit/decorators.js';
// eslint-disable-next-line import/named
import { html, LitElement, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { money4dpToStr, moneyToStr, strToMoney } from './currency-formatter';
import { getInternalId } from './ui/databinding/databinding';
import { isEmptyOrSpace } from './ui/helper-functions';
import { getToolTip } from '../tooltip';
import { classMap } from "lit/directives/class-map.js";

export class InputView extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html``;
  }

  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr == undefined || attr !== 'false';
    }
  }
}

@customElement('bs-form-money')
export class FormInputMoneyView extends InputView {
  type = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  dp = 2;
  private _internalValue = '';

  static get properties() {
    return {
      'data-label': { type: String },
      type: { type: String },
      value: { type: String },
      dp: { type: Number },
      required: { type: Boolean },
      rows: { type: Number },
      readonly: { type: Boolean },
      maxlength: { type: Number }
    };
  }

  get value(): string {
    if (isEmptyOrSpace(this._internalValue)) return '';
    return strToMoney(this._internalValue, this.dp).toFixed(this.dp);
  }

  set value(v) {
    const old = this._internalValue;
    if (v === null || v === 'null') {
      this._internalValue = '';
    } else {
      this._internalValue = strToMoney(v, this.dp).toFixed(this.dp);
    }
    this.requestUpdate('value', old);
  }

  get realValue(): number | null {
    if (isEmptyOrSpace(this._internalValue)) return null;

    const v = strToMoney(this._internalValue);
    if (Number.isNaN(v)) return 0;
    else return v;
  }

  render() {
    const blurEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      //do not need to update internal value, its being done
      //via oninput.. doing it here causes problems if multiple
      //blur events are attached.
      if (strToMoney(this._internalValue) < 0) {
        if (!input.classList.contains('text-danger')) input.classList.add('text-danger');
      } else if (input.classList.contains('text-danger')) input.classList.remove('text-danger');

      input.value = this.displayValue(this._internalValue);
      console.log(`internal blur ${input.id}`);
    };
    const focusEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      if (input.classList.contains('text-danger')) input.classList.remove('text-danger');

      if (!isEmptyOrSpace(this._internalValue)) input.value = strToMoney(this._internalValue, this.dp).toFixed(this.dp);
      console.log(`focus ${input.id} value ${input.value}`);
      input.select();
    };

    const inputEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      const v = strToMoney(input.value, this.dp);
      if (Number.isNaN(v)) this._internalValue = '';
      else this._internalValue = input.value;
    };

    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const classes = `form-control fw-bold ${this.realValue && this.realValue < 0 ? 'text-danger' : ''}`;
    const elem = this.querySelector('input') as HTMLInputElement;
    let val = this.displayValue(this.value);
    if (elem) {
      //this means we have already rendered before, and we might be focused
      if (elem === document.activeElement) val = elem.value;
    }

    const labelTemplate = this.dataset.label
      ? html`<label for=${this.dataset.id} class="form-col-label">${this.dataset.label}:${reqSpan}</label>`
      : html``;

    return html`
      <div class="row mb-2 align-items-center form-col-item">
        ${labelTemplate}
        <div class="form-col-input">
          <input
            class="input-money"
            autocomplete="off"
            @input=${inputEvent}
            @blur=${blurEvent}
            @focus=${focusEvent}
            @keyup=${this.onkeyup}
            class=${classes}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .value=${val}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
          />
        </div>
      </div>
    `;
  }

  displayValue(_internalValue: string): string {
    if (isEmptyOrSpace(_internalValue)) return '';
    const val = strToMoney(_internalValue, this.dp);
    if (this.dp == 4) return money4dpToStr(val);
    else return moneyToStr(val);
  }
}

@customElement('bs-form-input')
export class FormInputInputView extends InputView {
  type = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  min: string | null = null;
  max: string | null = null;
  pattern: string | null = null;
  toolTip: string | null = null;

  static get properties() {
    return {
      'data-label': { type: String },
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      rows: { type: Number },
      readonly: { type: Boolean },
      maxlength: { type: String },
      min: { type: String },
      max: { type: String },
      pattern: { type: String },
      toolTip: { type: String }
    };
  }

  render() {
    const focusEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      if (input.type === 'text' || input.type === 'number') input.select();
    };
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const toolTip = this.toolTip ? getToolTip(this.toolTip) : html``;
    let classes = 'form-control';
    if (this.type.toLowerCase() == 'number') classes += '';
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="form-col-label">${this.dataset.label}:${reqSpan} ${toolTip}</label>
        <div class="form-col-input">
          <input
            @oninput=${this.oninput}
            @blur=${this.onblur}
            @keyup=${this.onkeyup}
            class=${classes}
            @focus=${focusEvent}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .type=${this.type}
            .value=${this.value}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
            min="${ifDefined(this.min)}"
            max="${ifDefined(this.max)}"
            pattern="${ifDefined(this.pattern)}"
          />
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-input-no-label')
export class FormInputInputNoLabelView extends InputView {
  type = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  min: string | null = null;
  max: string | null = null;
  step: string | null = null;

  @query('input') _input!: HTMLInputElement;

  static get properties() {
    return {
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      readonly: { type: Boolean },
      maxlength: { type: String },
      min: { type: String },
      max: { type: String },
      step: { type: String }
    };
  }

  render() {
    const focusEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      if (input.type === 'text' || input.type === 'number') input.select();
    };

    let classes = 'form-control';
    if (this.type.toLowerCase() == 'number') classes += '';
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <div class="form-col-input">
          <input
            @oninput=${this.oninput}
            @blur=${this._dispatchBlur}
            @keyup=${this.onkeyup}
            class=${classes}
            @focus=${focusEvent}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .type=${this.type}
            .value=${this.value}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
            min="${ifDefined(this.min)}"
            max="${ifDefined(this.max)}"
            name="${ifDefined(this.dataset.id)}"
            step=${ifDefined(this.dataset.step)}
          />
        </div>
      </div>
    `;
  }

  private _dispatchBlur() {
    const id = this._input.id;
    const value = this._input.value.trim();

    const options = {
      detail: { id, value },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent('bs-form-input-blur', options));
  }
}

@customElement('bs-form-display')
export class FormInputDisplayView extends InputView {
  value = '';

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String }
    };
  }

  render() {
    const classes = 'form-control-plaintext';

    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="form-col-label">${this.dataset.label}:</label>
        <div class="form-col-input">
          <input
            class=${classes}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            type="text"
            readonly
            .value=${this.value}
          />
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-checkbox')
export class FormInputCheckboxView extends InputView {
  value = '';
  required = false;
  readonly = false;
  checked = false;
  display: 'switch' | 'checkbox' | 'inline-checkbox' = 'checkbox';

  static get properties() {
    return {
      'data-label': { type: String },
      checked: { type: Boolean },
      required: { type: Boolean },
      readonly: { type: Boolean },
      inline: { type: Boolean },
      display: { type: String }
    };
  }

  toggleCheck() {
    this.checked = !this.checked;

    this.dispatchEvent(
        new CustomEvent('checkbox-changed', {
          detail: this.checked
        })
    );
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="form-check ${classMap({
        'form-check-inline': this.display === 'inline-checkbox',
        'form-switch': this.display === 'switch'
      })}">
          <input
            @oninput=${this.oninput}
            @blur=${this.onblur}
            class="form-check-input"
            @click=${this.toggleCheck}
            .id=${ifDefined(this.dataset.id)}
            type="checkbox"
            ?required=${this.required}
            ?readonly=${this.readonly}
            ?disabled=${this.readonly}
            ?checked=${this.checked}
          />

          <label for=${ifDefined(this.dataset.id)} class="form-check-label">${this.dataset.label} ${reqSpan}</label>
        </div>
    `;
  }
}

@customElement('bs-form-picker')
export class FormInputPickerView extends InputView {
  valuedisplay = '';
  valuedata: string | null = null;
  required = false;
  readonly = false;

  static get properties() {
    return {
      'data-label': { type: String },
      valuedisplay: { type: String, reflect: true },
      valuedata: { type: String, reflect: true },
      required: { type: Boolean },
      readonly: { type: Boolean }
    };
  }

  render() {
    const keyDownEvent = (e: Event) => {
      const kEvent = e as KeyboardEvent;
      if (kEvent.code === 'Enter') {
        e.preventDefault();
        this.clicked();
      }
      if (kEvent.code !== 'Tab') e.preventDefault();
    };
    const clickEvent = () => this.clicked();
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.dataset.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">
          <input
            class="form-control form-select"
            .id=${ifDefined(this.dataset.id)}
            type="text"
            .value=${this.valuedisplay}
            data-value=${ifDefined(this.valuedata)}
            data-picker="Yes"
            @click=${clickEvent}
            ?required=${this.required}
            ?readonly=${this.readonly}
            @keydown=${keyDownEvent}
          />
        </div>
      </div>
    `;
  }

  private clicked() {
    if (!this.readonly) this.dispatchEvent(new CustomEvent('picker-clicked'));
  }
}

@customElement('bs-paginator')
export class Paginator extends InputView {
  index = 1;
  count = 1;

  static get properties() {
    return {
      index: { type: String },
      count: { type: String }
    };
  }

  render() {
    let currentPage = this.index;
    const pageCount = this.count;

    const visiblePages = 11;
    const pageDeductor = 5;
    let firstDisplayPage = currentPage - pageDeductor;
    if (firstDisplayPage < 1) firstDisplayPage = 1;
    let lastDisplayPage = firstDisplayPage + visiblePages - 1;
    if (lastDisplayPage > pageCount) lastDisplayPage = pageCount;

    if (lastDisplayPage === pageCount && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      firstDisplayPage = Math.max(1, lastDisplayPage - visiblePages + 1);
    if (firstDisplayPage === 1 && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      lastDisplayPage = Math.min(pageCount, firstDisplayPage + visiblePages - 1);

    const includeSkipToFirst = firstDisplayPage > 1;
    const includeSkipToLast = lastDisplayPage < pageCount - 1;

    const clickEvent = (e: Event) => {
      e.preventDefault();
      const strPage = (e.target as HTMLUListElement).textContent?.toLowerCase();
      if (strPage === 'prev') currentPage--;
      else if (strPage === 'next') currentPage++;
      else if (strPage === 'last') currentPage = pageCount;
      else if (strPage === 'first') currentPage = 1;
      else currentPage = parseInt(strPage ?? '1');

      //handle accidental overflows
      currentPage = Math.min(pageCount, Math.max(1, currentPage));
      const event = new CustomEvent('page-change', {
        detail: {
          index: currentPage - 1
        }
      });
      this.dispatchEvent(event);
    };

    const pages = (): Array<TemplateResult> => {
      const pageResult: Array<TemplateResult> = [];
      for (let i = firstDisplayPage; i <= lastDisplayPage; i++) {
        if (i === currentPage) {
          pageResult.push(
            html` <li class="page-item active">
              <span class="page-link">${i}</span>
            </li>`
          );
        } else {
          pageResult.push(
            html` <li class="page-item">
              <a class="page-link" href="#" @click=${clickEvent}>${i}</a>
            </li>`
          );
        }
      }
      return pageResult;
    };

    const skipFirstClass = `page-item ${!includeSkipToFirst ? 'disabled' : ''}`;
    const currentPageClass = `page-item ${currentPage === 1 ? 'disabled' : ''}`;
    const lastPageClass = `page-item ${currentPage === pageCount ? 'disabled' : ''}`;
    const skipToLastClass = `page-item ${!includeSkipToLast ? 'disabled' : ''}`;
    return html` <nav aria-label="Page navigation">
      <ul class="pagination pagination-sm justify-content-center">
        <li class=${skipFirstClass}>
          <a class="page-link" href="#" @click=${clickEvent}>First</a>
        </li>
        <li class=${currentPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Prev</a>
        </li>
        ${pages()}
        <li class=${lastPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Next</a>
        </li>
        <li class=${skipToLastClass}>
          <a class="page-link" href="#" @click=${clickEvent}>Last</a>
        </li>
      </ul>
    </nav>`;
  }
}

@customElement('bs-form-area')
export class FormInputAreaView extends InputView {
  rows = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;

  static get properties() {
    return {
      'data-label': { type: String },
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      readonly: { type: Boolean },
      maxlength: { type: Number }
    };
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label .for=${ifDefined(this.dataset.id)} class="col-2 form-col-label">${this.dataset.label} :${reqSpan}</label>
        <div class="col-10 form-col-input">
          <textarea
            @oninput=${this.oninput}
            @blur=${this.onblur}
            @keyup=${this.onkeyup}
            class="form-control"
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .rows=${this.rows}
            .value=${this.value}
            ?required=${this.required}
            maxlength=${ifDefined(this.maxlength)}
            ?readonly=${this.readonly}
          >
          </textarea>
        </div>
      </div>
    `;
  }
}

export interface FormInputSelectValue {
  text: string;
  value: string;
  disabled: boolean;
}

export function dlSelectValues(input: any[], convert: (x: any) => FormInputSelectValue): string {
  return JSON.stringify(input.map(x => convert(x)));
}

export function getEnumKeys(e: any, keepZeroValue = true): string[] {
  return Object.keys(e).filter(o => {
    const value = parseInt(o);
    if (isNaN(value)) return false;
    if (!keepZeroValue && value === 0) return false;
    return true;
  });
}

export function getEnum<E>(e: E, value) {
  return parseInt(getEnumKeys(e)[value]);
}

//todo.. rename these. this name was based on a personal project that they are copied from
export function dlEnumSelectValues<E>(e: E, keepZeroValue = true): string {
  return dlSelectValues(getEnumKeys(e, keepZeroValue), key => {
    return { text: e[key], value: key, disabled: false };
  });
}

/**
 *
 * @param input an array of objects
 * @param convert a converter to turn an object from input into a FormInputSelect
 * @returns a stringified json object to pass as a parameter
 *
 * * @example
 *
 * ```ts
 *
 * let test = [ {name:"bob" , id:1, age:34},{name:"tom" ,age:27, id:2} ]
 *
 * const options = MapArrayToFormInputSelectValue(test,(obj:any)=> {text:obj.name, value:obj.id} )
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */

export function MapArrayToFormInputSelectValue<ObjectType>(
  input: ObjectType[],
  convert: (x: ObjectType) => FormInputSelectValue
): string {
  return JSON.stringify(input.map(x => convert(x)));
}

/**
 *
 * @param typeValue the enumerated type to convert to a picker selection
 *
 * @param keepZeroValue if true, we keep the zero value, assuming it is part of a filter None/All etc
 * if false, we exlude the 0
 * @returns a string containing jsonarray of data to pass to the options attribute for <bs-form-select>
 *
 * @example
 *
 * ```ts
 * enum Test
 * {
 *    all = 0,
 *    one = 1,
 *    two = 2
 * }
 * const options = ConvertEnumToFormSelectOptions(Test, true)
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */
export function ConvertEnumToFormSelectOptions<EnumeratedType>(typeValue: EnumeratedType, keepZeroValue = false) {
  return dlEnumSelectValues(typeValue, keepZeroValue);
}

@customElement('bs-form-select')
export class FormInputSelectView extends InputView {
  value?: string;
  options?: string | FormInputSelectValue[];
  required?: boolean = false;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String },
      options: { type: Object },
      required: { type: Boolean }
    };
  }

  render() {
    const internalOptions = this.options ?? [];

    const optionsArray: FormInputSelectValue[] =
      typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;
    const options: HTMLOptionElement[] = [];
    optionsArray.forEach(x =>
      options.push(new Option(x.text, x.value, undefined, x.value.toString() == this.value?.toString()))
    );
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const changeEvent = e => {
      this.value = e.target.value;
      this.dispatchEvent(
        new CustomEvent('wm-select-changed', {
          detail: this.value
        })
      );
    };
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">
          <select
            class="form-select"
            ?readonly=${this.attributeTrue('readonly')}
            ?disabled=${this.attributeTrue('disabled')}
            @change=${changeEvent}
            .value=${this.value}
            placeholder=${this.dataset.placeholder}
            .id=${ifDefined(this.dataset.id)}
            aria-placeholder=${this.dataset.placeholder}
            aria-label=${this.dataset.label}
            ?required=${this.required}
          >
            ${options}
          </select>
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-radio-group')
export class FormInputRadioGroupView extends InputView {
  required = false;
  readonly = false;
  options = '';
  value = '';
  horizontal = true;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String, reflect: true },
      required: { type: Boolean },
      readonly: { type: Boolean },
      options: { type: String },
      horizontal: { type: Boolean }
    };
  }

  toggleCheck(selectedValue: string) {
    this.value = selectedValue;

    this.dispatchEvent(
      new CustomEvent('radio-changed', {
        detail: selectedValue
      })
    );
  }

  render() {
    let optionsArray: FormInputSelectValue[] = [];
    try {
      optionsArray = JSON.parse(this.options ?? '[]');
    } catch {
      throw new Error(`Invalid options: "${this.options}" on ${this.dataset.id}`);
    }
    const readonly = this.readonly;

    const optionsTemplates = optionsArray.map((option, index) => {
      const checked = option.value == this.value;
      const inputId = this.dataset.id ?? getInternalId();
      const isLine = this.horizontal ? 'form-check-inline' : '';

      const clickEvent = readonly
        ? (e: Event) => {
            e.preventDefault();
          }
        : () => this.toggleCheck(option.value);
      const forId = 'rbg-' + inputId + index;
      return html`
        <div class="form-check ${isLine}">
          <input
            class="form-check-input"
            type="radio"
            value=${option.value}
            name=${this.dataset.id}
            id="${' rbg-' + inputId + index}"
            ?checked=${checked}
            ?disabled=${readonly || option.disabled}
            ?readonly=${readonly}
            @click=${clickEvent}
          />
          <label class="form-check-label" for=${forId}> ${option.text} </label>
        </div>
      `;
    });
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">${optionsTemplates}</div>
      </div>
    `;
  }
}

@customElement('bs-form-image-upload')
export class FormInputImageFileUploadView extends InputView {
  value?: string;
  required?: boolean = false;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String },
      required: { type: Boolean }
    };
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const changeEvent = e => this.onPreview(e);
    const imgId = `${this.dataset.id}-img`;
    return html`
      <div class="row mb-3 align-items-center form-col-item image-upload-field">
        <label for=${this.dataset.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">
          <input
            id="${this.dataset.id}"
            class="form-control"
            type="file"
            accept="image/*"
            @change=${changeEvent}
            value=${this.value}
          />
        </div>
      </div>
      <div class="row mb-3 align-items-center form-col-item image-upload-image">
        <div class="col-10 offset-0 offset-sm-2 offset-md-3 offset-xl-2 form-col-input">
          <div class="image-upload-wrapper">
            <img src=${this.value} id=${imgId} class="img-fluid " />
          </div>
        </div>
      </div>
    `;
  }

  /**
   * Used to display a preview of a selected image file
   * @param event
   */
  private async onPreview(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target?.files?.item(0);
    if (file) {
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (validImageTypes.includes(file.type)) {
        this.value = window.URL.createObjectURL(file as Blob);
      }
    }
  }
}
