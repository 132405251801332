import { tlang } from '@softtech/webmodule-components';
import { EventSnippet } from './events';

import { WaitPatiently } from './modal-spinner';

//a progress window default to run will doing a save to the server
export class WaitPatientlySaving extends WaitPatiently {
  constructor(title?: EventSnippet, message?: EventSnippet) {
    super(title ?? (() => tlang`Saving`), message ?? (() => tlang`please wait while we update the data`));
  }
  borderType() {
    return 'basic';
  }
}

export interface ISaveNotifier {
  done: () => Promise<void>;
}

export function saveIndicator(title?: EventSnippet, message?: EventSnippet): ISaveNotifier {
  const saving = new WaitPatientlySaving(title, message);
  return {
    done: async () => await saving.hideModal()
  };
}
