import { tlang } from '@softtech/webmodule-components';
import { Snippet } from '@softtech/webmodule-data-contracts';
import { html } from 'lit-html';
import { customElement, property } from 'lit/decorators.js';
import { DashboardWidget } from './dashboard-widget';
import { ConvertedQuoteTotalsCache } from '../data/converted-quote-totals-cache';
import { DateRange } from './dashboard-filter';
import { localDateTimeToServer } from '../../../components/datetime-converter';
import { emptyGuid } from '../../../api/guid';

const ApexCharts = (globalThis as any).ApexCharts;

@customElement('sales-success-widget')
export class SalesSuccessWidget extends DashboardWidget {
  @property() totalQuotes: number = 0;
  @property() totalPurchaseOrders: number = 0;

  @property() dateRange?: DateRange;
  @property() branchIds?: string[] = [];
  @property() userId?: string;

  @property()
  private _cache?: ConvertedQuoteTotalsCache | undefined;
  public get cache(): ConvertedQuoteTotalsCache | undefined {
    return this._cache;
  }
  public set cache(value: ConvertedQuoteTotalsCache | undefined) {
    this._cache = value;
  }

  chart: any | null = null;

  protected async refreshData(): Promise<void> {
    if (this.dateRange) {
      const startDate = localDateTimeToServer(this.dateRange.startDate);
      const endDate = localDateTimeToServer(this.dateRange.endDate);
      const result = await this._cache?.getData(startDate, endDate, this.branchIds, this.userId ?? emptyGuid);
      if (result) {
        this.totalPurchaseOrders = result.purchaseOrderCount;
        this.totalQuotes = result.quoteCount;
      }
    }
  }

  protected async firstUpdated(): Promise<void> {
    await this.refreshData();
    const options = {
      series: [this.totalPurchaseOrders, this.unconvertedQuoteCount],
      labels: [tlang`Converted !!quote!!`, tlang`Unconverted !!quote!!`],
      colors: ['#3282CC', '#C7CBD0'],
      chart: {
        type: 'donut',
        height: '120%',
        offsetY: 0
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: tlang`Converted !!quote!!`,
                formatter: () => this.getChartLabel()
              },
              name: {
                show: false
              },
              value: {
                show: true
              }
            }
          }
        }
      },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      }
    };
    this.chart = new ApexCharts(this.querySelector('.salesSuccessChart'), options);
    this.chart.render();
  }

  protected getHeader(): Snippet {
    return tlang`Sales Success`;
  }

  protected getBody(): Snippet {
    return html`
      <div class="container">
        <div class="row chart-widget-data-wrapper">
          <div class="col-6 chart-widget-data">
            <div class="display-5 text-primary">${this.totalPurchaseOrders} / ${this.totalQuotes}</div>
            <div class="display-6 text-secondary">${tlang`Conversion !!quote!! to !!order!!`}</div>
          </div>
          <div class="col-6 chart-widget-chart">
            <div class="salesSuccessChart"></div>
          </div>
        </div>
      </div>
    `;
  }

  protected get unconvertedQuoteCount(): number {
    return this.totalQuotes - this.totalPurchaseOrders;
  }

  protected getChartLabel(): string {
    const result = ((this.totalPurchaseOrders + Number.EPSILON) / this.totalQuotes) * 100;
    const percentage = Math.round(result);
    // if there are currently no quotes in the system, we get a infinity result due to division by 0,
    // so check to make sure we have a finite result to return as a percentage.
    if (Number.isFinite(percentage)) {
      return `${percentage}%`;
    }
    return `0%`;
  }
}
