import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TenantLoginPublicInfo } from '../../api/supplier-api-interface-user';
import { emptyGuid } from '../../api/guid';
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import { LitElementBase } from '../../components/ui/litelement-base';

@customElement('webmodule-assignto')
export class WebModuleAssignUserEdit extends LitElementBase {
  @property() users?: TenantLoginPublicInfo[];
  @property() value?: string;

  connectedCallback(): void {
    super.connectedCallback();
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
  }
  eventPrefix() {
    return 'wm-event';
  }

  protected render(): unknown {
    const users = this.users ?? [];
    const optionTemplate = (text: string, value: string) => {
      return new Option(text, value, undefined, value.toString() == this.value?.toString());
    };
    const userId = getCurrentUser()?.id ?? emptyGuid;
    const me = users.find(x => x.id === userId);

    const userTemplates = users
      .filter(x => x.id !== userId)
      .sort((a, b) => {
        const namea = a.friendlyName ?? '';
        const nameb = b.friendlyName ?? '';
        return namea.localeCompare(nameb);
      })
      .map(x => optionTemplate(x.friendlyName ?? '', x.id));

    const optionsTemplates = [
      optionTemplate(tlang`Unassigned`, emptyGuid),
      optionTemplate(me?.friendlyName ?? '', me?.id ?? emptyGuid),
      ...userTemplates
    ];
    const changeEvent = e => {
      this.value = e.target.value ?? emptyGuid;
      this.dispatchCustom('changed', { value: this.value ?? emptyGuid });
    };
    return html`
      <select
        class="form-control form-select inline-form-control"
        .value=${this.value}
        @change=${changeEvent}
        ?readonly=${this.attributeTrue('readonly')}
        ?disabled=${this.attributeTrue('disabled')}
      >
        ${optionsTemplates}
      </select>
    `;
  }
}
