import { ApiCommunications, NullPromise } from '@softtech/webmodule-data-contracts';
import { FranchiseeNetworkApi } from './franchisee-network-api';
import { ApiBase } from './api-base';
import {
  InputCreateFranchisee,
  InputUpdateFranchiseeSupplierProperties,
  InputUpdateFranchiseeDeploymentConfiguration,
  InputUpdateSupplierReferenceCodeOnTenant,
  InputUpdateSupportInboxItem,
  RequestGetActiveUserInformation,
  RequestGetFranchiseeDetails,
  RequestGetSupportInbox,
  RequestPAT,
  ResultCreateFranchisee,
  ResultGetActiveUserInformation,
  ResultGetAllFranchiseeDetails,
  ResultGetFranchiseeDetails,
  ResultGetSupportInbox,
  ResultPAT,
  ResultUpdateFranchiseeSupplierProperties,
  ResultUpdateFranchiseeDeploymentConfiguration,
  ResultUpdateSupplierReferenceCodeOnTenant,
  ResultUpdateSupportInboxItem
} from './supplier-api-interface-franchiseenetwork';

export class FranchiseeNetworkApiImplementation extends ApiBase implements FranchiseeNetworkApi {
  constructor(api: ApiCommunications) {
    super(api, 'api/FranchiseeNetwork/');
    this.api = api;
  }

  async getFranchiseeDetails(request: RequestGetFranchiseeDetails): NullPromise<ResultGetFranchiseeDetails> {
    return await this.post<ResultGetFranchiseeDetails>('GetFranchiseeDetails', request);
  }
  async createFranchisee(input: InputCreateFranchisee): NullPromise<ResultCreateFranchisee> {
    return await this.post<ResultCreateFranchisee>('CreateFranchisee', input);
  }
  async updateFranchisee(
    input: InputUpdateFranchiseeSupplierProperties
  ): NullPromise<ResultUpdateFranchiseeSupplierProperties> {
    return await this.post<ResultCreateFranchisee>('UpdateFranchisee', input);
  }
  async updateFranchiseeDeploymentConfiguration(
    input: InputUpdateFranchiseeDeploymentConfiguration
  ): NullPromise<ResultUpdateFranchiseeDeploymentConfiguration> {
    return await this.post<ResultUpdateFranchiseeDeploymentConfiguration>(
      'UpdateFranchiseeDeploymentConfiguration',
      input
    );
  }
  async getAllFranchiseeDetails(): NullPromise<ResultGetAllFranchiseeDetails> {
    return await this.post<ResultGetAllFranchiseeDetails>('GetAllFranchiseeDetails', {});
  }

  async updateTenantCustomerCodes(
    input: InputUpdateSupplierReferenceCodeOnTenant
  ): NullPromise<ResultUpdateSupplierReferenceCodeOnTenant> {
    return await this.post<ResultUpdateSupplierReferenceCodeOnTenant>('UpdateSupplierReferenceCodeOnTenant', input);
  }

  async pat(request: RequestPAT): NullPromise<ResultPAT> {
    return await this.post<ResultPAT>('PAT', request);
  }

  async getActiveUserInformation(
    request: RequestGetActiveUserInformation
  ): NullPromise<ResultGetActiveUserInformation> {
    return await this.post<ResultGetActiveUserInformation>('getActiveUserInformation', request);
  }

  async getQuoteSupportInbox(request: RequestGetSupportInbox): NullPromise<ResultGetSupportInbox> {
    return await this.post<ResultGetSupportInbox>('GetInbox', request, 'api/QuoteSupport/');
  }
  async updateSupportInboxItem(input: InputUpdateSupportInboxItem): NullPromise<ResultUpdateSupportInboxItem> {
    return await this.post<ResultUpdateSupportInboxItem>('updateSupportInboxItem', input, 'api/QuoteSupport/');
  }
}
