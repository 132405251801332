import { tlang } from '@softtech/webmodule-components';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { constructAsync } from '../../async-constructor';

import { PageBase } from './helpers/page-base';

import { SupportInboxView } from '../../dealer/view/supportinbox-view';
import { disposeOf } from '../../components/dispose';
import { refreshAllUserCache } from '../../dealer/data/all-user-cache';

@customElement('app-support-inbox')
export class AppSupportInbox extends PageBase {
  @state()
  view: SupportInboxView | null = null;

  constructor() {
    super();
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.view);
    this.view = null;
  }

  render() {
    if (!this.loggedIn) return html``;
    return html` <div class="page-content support-inbox-content">
      <div class="page-container">${this.view?.ui}</div>
    </div>`;
  }

  meta() {
    return {
      title: tlang`%%franchisee-network%%`,
      description: tlang`%%franchisee-network%% settings page`
    };
  }

  protected async canLeavePage(): Promise<boolean> {
    if (this.view) return await this.view.canClose();
    else return true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async viewFactory(): Promise<SupportInboxView> {
    return await constructAsync(new SupportInboxView());
  }

  protected async afterUserConnected() {
    await refreshAllUserCache();
    this.view = await this.viewFactory();
    await this.view.render();
    this.render();
  }
}
