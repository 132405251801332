import { ApiCommunications, NullPromise } from '@softtech/webmodule-data-contracts';
import { RequestGetSupplierTerms, ResultGetSupplierTerms, SupplierApi } from './supplier-api';
import { tenantId } from '@softtech/webmodule-components';
import { ResultGetAllUserProfileOfTenant, TenantLoginPublicInfo } from './supplier-api-interface-user';
import {
  AllSupplierSettings,
  InputCreateTerritory,
  InputDeleteTerritory,
  InputLinkFranchiseeBranchToTerritory,
  InputUnlinkFranchiseeBranchFromTerritory,
  InputUpdateCoreSupplierSettings,
  InputUpdateTerritories,
  RequestGetTerritorySummary, RequestSystemConfiguration,
  ResultCreateTerritory,
  ResultDeleteTerritory,
  ResultGetCoreSupplierSettings,
  ResultGetTerritorySummary,
  ResultLinkFranchiseeBranchToTerritory, ResultSystemConfiguration,
  ResultUnlinkFranchiseeBranchFromTerritory,
  ResultUpdateCoreSupplierSettings,
  ResultUpdateTerritories
} from './supplier-api-interface-supplier';

export class SupplierApiImplementation implements SupplierApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }
  async getCoreSupplierSettingsEntry(): NullPromise<AllSupplierSettings> {
    return (
      (await this.api.post<ResultGetCoreSupplierSettings>('api/Settings/GetCoreSupplierSettingsEntry', {}))
        ?.allSettings ?? null
    );
  }
  async updateCoreSupplierSettingsEntry(input: InputUpdateCoreSupplierSettings): NullPromise<AllSupplierSettings> {
    const r = await this.api.post<ResultUpdateCoreSupplierSettings>(
      'api/Settings/UpdateCoreSupplierSettingsEntry',
      input
    );
    return r?.settings ?? null;
  }

  // This needs to be updated once we have a supplier portal. For now, we just add the markdown file in the
  // dealer blob container and read the information from there.
  async getSupplierTerms(request: RequestGetSupplierTerms): NullPromise<ResultGetSupplierTerms> {
    const url = this.api.fullUrl(`api/file/T${tenantId()}/services/supplier/${request.supplierId}/tac.md`);

    const response = await fetch(`${url}`);

    if (response.status == 200 || response.status == 206) {
      const text = await response.text();

      return { TermsAndConditions: text };
    } else return null;
  }

  async getAllUsers(): NullPromise<TenantLoginPublicInfo[]> {
    const result = await this.api.post<ResultGetAllUserProfileOfTenant>('api/user/GetAllUserProfileOfTenant', {});
    return result?.userProfiles ?? [];
  }

  async getSystemConfiguration(request: RequestSystemConfiguration): NullPromise<ResultSystemConfiguration> {
    return await this.api.post('api/Settings/GetSystemConfiguration', request);
  }

  async getSupplierTerritories(request: RequestGetTerritorySummary): NullPromise<ResultGetTerritorySummary> {
    return await this.api.post('api/Settings/GetTerritories', request);
  }

  async createSupplierTerritory(input: InputCreateTerritory): NullPromise<ResultCreateTerritory> {
    return await this.api.post('api/Settings/CreateTerritory', input, true);
  }

  async updateSupplierTerritories(input: InputUpdateTerritories): NullPromise<ResultUpdateTerritories> {
    return await this.api.post('api/Settings/UpdateTerritories', input);
  }

  async deleteSupplierTerritory(input: InputDeleteTerritory): NullPromise<ResultDeleteTerritory> {
    return await this.api.post('api/Settings/DeleteTerritory', input);
  }

  async linkFranchiseeBranchToTerritory(
    input: InputLinkFranchiseeBranchToTerritory
  ): NullPromise<ResultLinkFranchiseeBranchToTerritory> {
    return await this.api.post('api/Settings/LinkFranchiseeBranchToTerritory', input);
  }

  async unlinkFranchiseeBranchFromTerritory(
    input: InputUnlinkFranchiseeBranchFromTerritory
  ): NullPromise<ResultUnlinkFranchiseeBranchFromTerritory> {
    return await this.api.post('api/Settings/UnlinkFranchiseeBranchFromTerritory', input);
  }
}
