import { PromiseTemplate, Snippet } from '@softtech/webmodule-data-contracts';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';

import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '@softtech/webmodule-components';
import { html } from 'lit';
import { isAutoSaving } from '../../components/save-workflow';
import { fireQuickSuccessToast } from '../../toast-away';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { FileContainer, FileContainerManager } from '../data/file-content-container';
import { Editor } from '@toast-ui/editor';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import { stringToBase64 } from '../../blob/converters';

export class SettingsTermsOfUseView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private termsOfUseManager: FileContainerManager;
  private editor?: Editor;

  constructor() {
    super();
    this.pageFragment = 'tou';

    this.termsOfUseManager = new FileContainerManager(
      new FileContainer(this.settingsManager.supplierSettings.termsOfUseVirtualPath),
      () => tlang`Terms of Use`
    );
    this.termsOfUseManager.afterSave.push(async () => {
      this.settingsManager.updateInput.base64TermsOfUse = stringToBase64(this.termsOfUseManager.fileContent);
      await this.settingsManager.saveSettings();
      await this.render();
    });
  }

  public async afterConstruction(): Promise<void> {
    await this.render();
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Terms of Use Agreement`;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    const termsOfUseText = this.editor?.getMarkdown();

    if (isEmptyOrSpace(termsOfUseText)) {
      errors.push(tlang`No Terms of Use specified`);
    }

    return errors;
  }

  async prepareForSave(): Promise<void> {
    this.termsOfUseManager.fileContent = this.editor?.getMarkdown() ?? '';
  }

  public internalDataChanged(): boolean {
    return this.settingsManager.changed() || this.termsOfUseManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    await this.termsOfUseManager.needsFile();

    const element = document.querySelector('#touEditor') as HTMLElement | null;
    if (element) {
      this.editor = new Editor({
        el: element,
        previewStyle: 'vertical',
        height: '500px',
        initialValue: this.termsOfUseManager.fileContent,
        usageStatistics: false,
        initialEditType: 'wysiwyg'
      });
    }

    await this.render();
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  protected async internalSaveData(): Promise<boolean> {
    await this.termsOfUseManager.save(true);

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`${this.getCaption()} saved`);
      await this.render();
      return true;
    }

    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html` <div>
      <form id="formTermsOfUseSettings" class="form-one-col">
        <h2>${tlang`General Terms of Use Agreement`}</h2>
        <div class="row">
          <div class="col-sm-12 form-column">
            <div id="touEditor"></div>
          </div>
        </div>
      </form>
    </div>`;
  }
}
