import { tlang } from '@softtech/webmodule-components';
import { ContainerManager } from '../../components/container-manager';
import { DevelopmentError } from '../../development-error';
import { newGuid } from '../../api/guid';
import { PricingRule, PricingRuleLibrary, PricingRuleType } from '../../api/supplier-api-interface-supplier';
import { getSettingsManager } from '../../supplier/common/supplier-settings';

export function getRegularRetailPricingRuleType(): PricingRuleType {
  return {
    id: 'ef92e07d-848e-4cf7-b1da-148c84ecee93',
    name: tlang`%%rrp-abbrev%%`,
    hasMinMax: false
  };
}

export function getMinMaxMarginPricingRuleType(): PricingRuleType {
  return {
    id: '116df47a-eb91-4d8b-872c-a63760038099',
    name: tlang`%%min%%/%%max%% Margin`,
    hasMinMax: true
  };
}

export class PricingRulesManager extends ContainerManager<PricingRuleLibrary> {
  empty(): boolean {
    return this.container.pricingRules.length === 0 && this.container.pricingRuleTypes.length === 0;
  }
  constructor() {
    super(getSettingsManager().updateInput.pricingRules!, () => tlang`%%pricing-rule%%`, tlang`%%pricing-rule%%`);
  }

  public get pricingRules(): PricingRule[] {
    if (this.container.pricingRules) {
      return this.container.pricingRules;
    }
    throw new DevelopmentError(`pricing-rules-manager.ts Attempting to use undefined pricingRules`);
  }

  public get pricingRuleTypes(): PricingRuleType[] {
    if (this.container.pricingRuleTypes) {
      return this.container.pricingRuleTypes;
    }
    throw new DevelopmentError(`pricing-rules-manager.ts Attempting to use undefined pricingRuleTypes`);
  }

  protected async internalSave(): Promise<boolean> {
    const sm = getSettingsManager();
    sm.updateInput.pricingRules = this.container;
    if (await sm.saveSettings()) {
      this.container = sm.updateInput.pricingRules;
      this.backup = this.clone(this.container);
      return true;
    }
    return false;
  }

  public getPricingRuleType(pricingRuleTypeId: string): PricingRuleType | null {
    const index = this.pricingRuleTypes.findIndex(r => r.id == pricingRuleTypeId);
    if (index >= 0) {
      return this.pricingRuleTypes[index];
    }
    return null;
  }

  public getPricingRule(pricingRuleId: string): PricingRule | null {
    const index = this.pricingRules.findIndex(r => r.id == pricingRuleId);
    if (index >= 0) {
      return this.pricingRules[index];
    }
    return null;
  }

  public createPricingRule(pricingRule: PricingRule) {
    pricingRule.id = newGuid();
    this.container.pricingRules.push(pricingRule);
  }

  public editPricingRule(pricingRule: PricingRule) {
    const index = this.pricingRules.findIndex(r => r.id == pricingRule.id);
    if (index >= 0) {
      this.container.pricingRules[index] = pricingRule;
    }
  }

  public deletePricingRule(pricingRule: PricingRule) {
    const index = this.pricingRules.findIndex(r => r.id == pricingRule.id);
    if (index >= 0) {
      this.container.pricingRules.splice(index, 1);
    }
  }

  public setDefaultPricingRuleId(pricingRuleId: string) {
    this.container.defaultPricingRuleId = pricingRuleId;
  }
}
