import { IconChecked } from './icon-checked';
import { IconDelete } from './icon-delete';
import { IconPlus } from './icon-plus';
import { IconRefresh } from './icon-refresh';
import { IconSave } from './icon-save';

export const iconRegistry = {
  checked: IconChecked,
  delete: IconDelete,
  plus: IconPlus,
  iconRefresh: IconRefresh,
  iconSave: IconSave
};
