import { html, TemplateResult } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import { getInternalId } from '../../components/ui/databinding/databinding';

import { tlang } from '@softtech/webmodule-components';

import { PageBase } from './helpers/page-base';
import { ActiveLicensesWidget } from '../dashboard/widget/active-licenses-widget';
import {
  DeliveryPipelinePoTablePageCache,
  DeliveryPipelinePoWidget
} from '../dashboard/widget/delivery-pipeline-purchaseorder-widget';
import { SalesSuccessWidget } from '../dashboard/widget/sales-success-widget';
import { ConvertedQuoteTotalsCache } from '../dashboard/data/converted-quote-totals-cache';
import { GeneratedPurchaseOrdersWidget } from '../dashboard/widget/generated-purchase-orders-widget';
import { GeneratedQuotesWidget } from '../dashboard/widget/generated-quotes-widget';
import { DashboardFilterOption, DateRange, DashboardFilterOptions } from '../dashboard/widget/dashboard-filter';
import { emptyGuid, newGuid } from '../../api/guid';
import { userDataStore } from '../common/current-user-data-store';
import { dashboardFilterDataCache } from '../dashboard/data/dashboard-filter-data-cache';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { getTerritoryManager, TerritoryContainerManager } from '../../settings/data/territory-manager';

/**
 * configure the dashboard based on the type of user.
 * we will determine this user level by using a security level on the backend
 * and converting that to a type
 * or maybe add a table that the manager can allocate and configure
 */
const dashboardContentType = {
  unknown: 0,
  /**
   * this is the typical quote creator, and only one implemented
   */
  branchUser: 1,
  franchiseManager: 2,
  franchiseAdministrator: 4
};

@customElement('app-home')
export class AppHome extends PageBase {
  internalId: string = getInternalId();
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = tlang`Welcome!`;
  @property() contentType = 0;

  private currentDate = new Date();

  private availableDateRanges: DateRange[] = [];
  @property() private selectedDateRange?: DateRange;
  @property() private selectedTerritoryId?: string;
  @property() private selectedFranchiseeId?: string;
  @property() private selectedBranchId?: string;
  @property() private selectedUserId?: string;

  private convertedQuoteTotalsData = new ConvertedQuoteTotalsCache();

  private deliveryPipelinePoWidgetData = new DeliveryPipelinePoTablePageCache();
  private dashboardFilterCache = dashboardFilterDataCache();

  private territoryManager: TerritoryContainerManager | null = null;
  private availableTerritories: DashboardFilterOption[] = [];

  constructor() {
    super();
    this.contentType = dashboardContentType.branchUser;
  }

  private get elementId(): string {
    return `dashboard-page-${this.internalId}`;
  }

  protected async awaken() {
    //if we ever reach this page and we are not logged in
    //then we want to force a page reload which will
    //take us through our full reload process of the apoplication
    //in index.html including the user login process
    if (!this.loggedIn) {
      setTimeout(() => (location.href = ''), 100);
    }

    const last6months = new Date(this.currentDate);
    last6months.setMonth(last6months.getMonth() - 6);
    this.addDateRange(tlang`Last 6 months`, last6months);

    const last90days = new Date(this.currentDate);
    last90days.setMonth(last90days.getMonth() - 3);
    this.addDateRange(tlang`Last 90 days`, last90days);

    const last60days = new Date(this.currentDate);
    last60days.setMonth(last60days.getMonth() - 2);
    this.addDateRange(tlang`Last 60 days`, last60days);

    // get start date for activity over the past month
    const last30days = new Date(this.currentDate);
    last30days.setMonth(last30days.getMonth() - 1);
    this.addDateRange(tlang`Last 30 days`, last30days);

    const last7days = new Date(this.currentDate);
    last7days.setDate(last7days.getDate() - 7);
    this.addDateRange(tlang`Last 7 days`, last7days);

    const last24hours = new Date(this.currentDate);
    last24hours.setDate(last24hours.getDate() - 1);
    this.addDateRange(tlang`Last 24 hours`, last24hours);

    userDataStore.requestRefresh();
    await this.dashboardFilterCache.prefetch();

    this.territoryManager = getTerritoryManager();
    await this.territoryManager.needsTerritories();
    for (const territory of this.territoryManager.territories) {
      this.availableTerritories.push({ id: territory.id, name: territory.name });
    }

    this.selectedDateRange = this.availableDateRanges[3];
  }
  async dispose() {
    //any dashboard data cached would need to be flushed here
    await super.dispose();
  }
  protected async afterUserConnected() {
    this.requestUpdate();
  }
  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.getTemplateForCurrentUser()}</div>
      </div>
    `;
  }

  getTemplateForCurrentUser(): TemplateResult {
    const selectedBranchIds = this.getSelectedBranchIds();

    const deliveryPipelineWidget = new DeliveryPipelinePoWidget();
    deliveryPipelineWidget.cache = this.deliveryPipelinePoWidgetData;
    deliveryPipelineWidget.branchId = this.selectedBranchId;
    deliveryPipelineWidget.assignedToUserId = this.selectedUserId;

    const activeLicensesWidget = new ActiveLicensesWidget();
    activeLicensesWidget.branchIds = selectedBranchIds;

    const salesSuccessWidget = new SalesSuccessWidget();
    salesSuccessWidget.cache = this.convertedQuoteTotalsData;
    salesSuccessWidget.dateRange = this.selectedDateRange;
    salesSuccessWidget.branchIds = selectedBranchIds;
    salesSuccessWidget.userId = this.selectedUserId;

    const generatedPurchaseOrdersWidget = new GeneratedPurchaseOrdersWidget();
    generatedPurchaseOrdersWidget.dateRange = this.selectedDateRange;
    generatedPurchaseOrdersWidget.branchIds = selectedBranchIds;
    generatedPurchaseOrdersWidget.userId = this.selectedUserId;

    const generatedQuotesWidget = new GeneratedQuotesWidget();
    generatedQuotesWidget.dateRange = this.selectedDateRange;
    generatedQuotesWidget.branchIds = selectedBranchIds;
    generatedQuotesWidget.userId = this.selectedUserId;

    return html`
      <div class="page-dashboard-content">
        <wm-dashboard-filter
          class="dashboard-item-filter"
          .dates=${this.availableDateRanges}
          .territories=${this.availableTerritories}
          .franchisees=${this.dashboardFilterCache.getFranchisees()}
          .branches=${this.dashboardFilterCache.getBranchesByFranchiseeId(this.selectedFranchiseeId)}
          .users=${this.dashboardFilterCache.getBranchUsers(this.selectedBranchId)}
          .selectedDate=${this.selectedDateRange}
          .selectedTerritoryId=${this.selectedTerritoryId}
          .selectedFranchiseeId=${this.selectedFranchiseeId}
          .selectedBranchId=${this.selectedBranchId}
          .selectedUserId=${this.selectedUserId}
          @wm-event-date-changed=${this.onDateRangeChanged}
          @wm-event-territory-changed=${this.onTerritoryChanged}
          @wm-event-franchisee-changed=${this.onFranchiseeChanged}
          @wm-event-branch-changed=${this.onBranchChanged}
          @wm-event-user-changed=${this.onUserChanged}
          @wm-event-reset-filters=${this.onFilterReset}
        ></wm-dashboard-filter>
        <div class="dashboard-items-wrapper filtered-dashboard-items">
          <div class="dashboard-item dashboard-item-piechart">${salesSuccessWidget}</div>
          <div class="dashboard-item dashboard-item-piechart">${activeLicensesWidget}</div>
          <div class="dashboard-item dashboard-item-barchart">${generatedPurchaseOrdersWidget}</div>
          <div class="dashboard-item dashboard-item-barchart">${generatedQuotesWidget}</div>
        </div>
        <div class="dashboard-items-wrapper unfiltered-dashboard-items">
          <div class="dashboard-item dashboard-item-table">${deliveryPipelineWidget}</div>
        </div>
      </div>
    `;
  }
  meta() {
    return {
      title: `Supplier's Module`,
      titleTemplate: null,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected addDateRange(label: string, startDate: Date) {
    this.availableDateRanges.push({
      id: newGuid(),
      label: label,
      startDate: startDate,
      endDate: this.currentDate
    });
  }

  protected getDashboardFilterOptions(): DashboardFilterOptions {
    return {
      dateRange: this.selectedDateRange,
      branchIds: this.getSelectedBranchIds(),
      userId: this.selectedUserId,
      forceEmptyDisplay: false
    };
  }

  /**
   * Gets an array of selected branch ids for a given territory or franchisee.
   * This is needed since the data aggregation service doesn't track the franchisee aggregate data belongs to, only the branch.
   * So instead we'll get the branch ids for a selected territory, or the branch id(s) for a given franchisee & branch combination.
   * @returns an array of ids.
   */
  protected getSelectedBranchIds(): string[] | undefined {
    if (!isEmptyOrSpace(this.selectedTerritoryId)) {
      const links = this.territoryManager!.getBranchLinksByTerritory(this.selectedTerritoryId!);
      const branchIds = links.map(l => l.branchId);
      if (branchIds.length > 0) {
        return branchIds;
      }
      return undefined;
    }

    if (isEmptyOrSpace(this.selectedFranchiseeId)) {
      return [];
    }

    if (isEmptyOrSpace(this.selectedBranchId)) {
      const branches = this.dashboardFilterCache.getBranchesByFranchiseeId(this.selectedFranchiseeId);
      if (branches) {
        return branches.map(b => b.id);
      }
      return undefined;
    }
    return [this.selectedBranchId!];
  }

  protected onDateRangeChanged = (e: CustomEvent<{ date?: DateRange }>) => {
    const dateRange = e.detail.date;
    if (dateRange) {
      this.selectedDateRange = dateRange;
    }
  };

  protected onTerritoryChanged = (e: CustomEvent<{ territoryId?: string }>) => {
    const territoryId = e.detail.territoryId;
    if (territoryId) {
      this.selectedTerritoryId = territoryId;
      this.selectedFranchiseeId = emptyGuid;
      this.selectedBranchId = emptyGuid;
      this.selectedUserId = emptyGuid;
    }
  };

  protected onFranchiseeChanged = (e: CustomEvent<{ franchiseeId?: string }>) => {
    const franchiseeId = e.detail.franchiseeId;
    if (franchiseeId) {
      this.selectedTerritoryId = emptyGuid;
      this.selectedFranchiseeId = franchiseeId;
      this.selectedBranchId = emptyGuid;
      this.selectedUserId = emptyGuid;
    }
  };

  protected onBranchChanged = (e: CustomEvent<{ branchId?: string }>) => {
    const branchId = e.detail.branchId;
    if (branchId) {
      this.selectedBranchId = branchId;
      this.selectedUserId = emptyGuid;
    }
  };

  protected onUserChanged = (e: CustomEvent<{ userId?: string }>) => {
    if (e.detail.userId) {
      this.selectedUserId = e.detail.userId;
    }
  };

  protected onFilterReset = (_: CustomEvent<{}>) => {
    if (this.availableDateRanges.length > 1) this.selectedDateRange = this.availableDateRanges[1];
    this.selectedTerritoryId = emptyGuid;
    this.selectedFranchiseeId = emptyGuid;
    this.selectedBranchId = emptyGuid;
    this.selectedUserId = emptyGuid;
  };
}
