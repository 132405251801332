import { tlang, WebmoduleIcon, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { PromiseTemplate, Snippet } from '@softtech/webmodule-data-contracts';
import { html, LitElement } from 'lit';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { customElement, property, query, state } from "lit/decorators.js";
import { WillCallLocation } from "../../api/supplier-api-interface-supplier";
import { SupplierSettingsManager } from "../../supplier/common/supplier-settings-manager";
import { getSettingsManager } from "../../supplier/common/supplier-settings";
import { editLocation } from "./locations-edit-modal";
import { singleLineAddress } from "../../components/ui/maps/map-helpers";
import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from "../../components/ui/modal-confirmation";

@customElement('settings-locations-table')
export class SettingsLocationsTable extends LitElement {
  static dependencies = {
    'webmodule-icon': WebmoduleIcon
  }

  private settingsManager: SupplierSettingsManager = getSettingsManager();

  @query('#settings-locations-table')
  table?: WebModuleLitTable;

  @property()
  public caption = "";

  @state()
  private _data?: WillCallLocation[] | null;

  constructor() {
    super();
    this._data = this.settingsManager.supplierSettings.willCallLocations;
  }

  dispatchCustom(action: "edit" | "delete", location: WillCallLocation) {
    const options = {
      detail: { ...location },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent(`wm-settings-location-${action}`, options));
  }

  public async refreshData()
  {
    this._data = [...this.settingsManager.supplierSettings.willCallLocations ?? []];
  }

  protected render() {
    const keyEvent = (item: WillCallLocation) => {
      return item.id;
    };

    const colDef = this.getColums();

    return html`
      <form id="settings-locations-form" class="form-one-col">
        <h2>${this.caption}</h2>
        <webmodule-lit-table
            id="settings-locations-table"
            class="settings-locations-table"
            .rowClass=${'tr'}
            .colClass=${'column'}
            .keyevent=${keyEvent}
            .tablestyle="nestedtable"
            .columns=${colDef}
            .pageLength="100"
            .data=${this._data ?? []}
            .clickrows=${false}
          >
          </webmodule-lit-table>
      </form>
    `;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private async openItem(item: WillCallLocation) {
    this.dispatchCustom("edit", item);
  }

  private async deleteItem(item: WillCallLocation) {
    this.dispatchCustom("delete", item);
  }

  private getColums(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: tlang`Location Title`,
      classes: 'colpxmax-100 settings-location-title',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as WillCallLocation;

        const clickEvent = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();

          await this.openItem(rowItem);
        };

        return html`<a class="settings-location-title-link" href="#" @click="${clickEvent}">${rowItem.title}</a>`;
      }
    });
    cols.push({
      title: tlang`Location Type`,
      classes: 'colpxmax-100 settings-location-type',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as WillCallLocation;

        return html`${rowItem.type}`;
      }
    });
    cols.push({
      title: tlang`Physical Address`,
      classes: 'colpxmax-160 settings-location-address',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as WillCallLocation;

        return html`${singleLineAddress(rowItem.willCallAddress)}`;
      }
    });
    cols.push({
      title: html``,
      classes: 'colpxmax-48 item-menu',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as WillCallLocation;

        return html`
          <div class="settings-location-item-actions">
            <webmodule-icon-button library="fa" name="trash-can" @click="${() => this.deleteItem(rowItem)}"></webmodule-icon-button>
            <webmodule-icon-button library="fa" name="pen-to-square" @click="${() => this.openItem(rowItem)}"></webmodule-icon-button>
          </div>`;
      }
    });

    return cols;
  }
}

export class SettingsLocationsView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();

  constructor() {
    super();
    this.pageFragment = 'locations';
  }

  public async afterConstruction(): Promise<void> {
    await this.settingsManager.needsSettings();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected getCaption(): Snippet {
    return tlang`Supplier Locations`;
  }

  async prepareForSave(): Promise<void> {
    //
  }

  public internalDataChanged(): boolean {
    return false;
  }

  async onEnter(): Promise<void> {
    await this.render();
  }

  protected async internalSaveData(): Promise<boolean> {
    //nothing to do
    return true;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html`<settings-locations-table
        id="settings-locations-table"
        .caption="${this.getCaption()}"
        @wm-settings-location-edit="${(e: CustomEvent<WillCallLocation>) => this.handleEdit(e)}"
        @wm-settings-location-delete="${(e: CustomEvent<WillCallLocation>) => this.handleDelete(e)}"
      >
    </settings-locations-table>`;
  }

  public async handleEdit(e: CustomEvent<WillCallLocation>): Promise<void> {
    const location = e.detail;
    if (await this.openEditor(location))
      await this.table.refreshData();
  }

  public async handleDelete(e: CustomEvent<WillCallLocation>){
    const location = e.detail;

    if (await AskConfirmation(tlang`Are you sure you want to delete the Will Call details?`,
        confirmationButtons[ConfirmationButtonType.yesNo]))
    {
    if (await this.settingsManager.removeWillCallLocation(location, true))
      await this.table.refreshData();
    }
  }

  public async openEditor(item: WillCallLocation | null) {
    return await editLocation(item);
  }

  get table(): SettingsLocationsTable {
    return this._ui?.querySelector('#settings-locations-table') as SettingsLocationsTable;
  }

  public buttonMenu(): Snippet {
    const newItem = async () => {
      const saved = await this.openEditor(null);

      if(saved)
        await this.table.refreshData();
    }

    return html`<button class="btn btn-primary"
        @click="${newItem}"
    >
        ${tlang`Add Supplier Location`}
      </button>`;
  }
}
