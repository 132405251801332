import { DataCache } from '../../cache/data-cache';

import { DevelopmentError } from '../../development-error';
import { TenantLoginPublicInfo } from '../../api/supplier-api-interface-user';

interface DealerCacheRegistry {
  userProfile: DataCache<TenantLoginPublicInfo>;
}

let _cache: DealerCacheRegistry | null = null;
let _event: (() => DealerCacheRegistry) | null = null;

export function setCacheRegistry(event: () => DealerCacheRegistry) {
  _event = event;
}
export function flushCache() {
  //do nothing for now;
  //if we get data that we need to clear, we dont want to clear it.
  // our methods are prefetch with await, then get local
  // clearing data breaks things..
  //will redesign to "expire" data. get local will always return data in the cache
  //expired or not. awaiting will reload on expiry.
  _cache = null; //logoff event
}

export function cache(): DealerCacheRegistry {
  if (!_cache) {
    _cache = _event?.() ?? null;
    if (!_cache) throw new DevelopmentError('DealerCacheRegistry setCacheRegistry invalid');
  }

  return _cache;
}
