import { EventSnippet, PromiseSnippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { TemplateResult, html } from 'lit';
import { isAutoSaving } from '../../components/save-workflow';
import { showValidationsWithDiscard } from '../../components/ui/modal-validationhandler';
import { tlang } from '@softtech/webmodule-components';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import '../../components/ui/maps/google-map';
import {
  BaseLogin,
  
  InputUpdateFranchiseeSupplierProperties,
  
  ResultGetFranchiseeDetails,
  SupplierTenantInfo,
  Tenant,
  ViewDeploymentTenants
} from '../../api/supplier-api-interface-franchiseenetwork';
import { getApiFactory } from '../../api/api-injector';
import { FranchiseeBranchDeploymentView } from '../data/franchisee-branch-deployment-view';
import { getTenantStatusDisplayValue } from './tenant-network-view';
import { userDataStore } from '../../supplier/common/current-user-data-store';
import { clone, compare } from '../../components/clone';
import { ILicenseServerApiCredentials, licenseServerUIBootLoader } from '../../settings/data/licenseserver-integration';
import { getCredentials } from '../../api/licenseserver-credentials';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { information } from '../../components/ui/modal-option';

export interface EditFranchiseeModalOptions {
  view: FranchiseeBranchDeploymentView;
  modalTitle: EventSnippet;
  saveButtonTitle: EventSnippet;
  cancelButtonTitle: EventSnippet;
}

interface InputChangeTenantProperties {
  globalSupplierSupplierId: string;
  tenantId: number;
  businessPhone: string;
  dealerDeploymentId: number;
  maxLicensePoolAllocation: number;
  primaryContact?: BaseLogin;
  defaultV6Reference: string;
}
interface UITenantProperties extends InputChangeTenantProperties {
  companyName: string;
  availableLicenses: number;
}

export class EditFranchiseeModal extends ModalViewBase {
  input: UITenantProperties = {
    availableLicenses: 0,
    globalSupplierSupplierId: '',
    tenantId: 0,
    dealerDeploymentId: 0,
    maxLicensePoolAllocation: 0,
    defaultV6Reference: '',
    businessPhone: '',
    companyName: ''
  };
  backup: UITenantProperties = {
    availableLicenses: 0,
    globalSupplierSupplierId: '',
    tenantId: 0,
    dealerDeploymentId: 0,
    maxLicensePoolAllocation: 0,
    defaultV6Reference: '',
    businessPhone: '',
    companyName: ''
  };
  options: EditFranchiseeModalOptions;
  viewDeploymentTenants?: ViewDeploymentTenants;
  tenantInfo?: SupplierTenantInfo;
  tenant?: Tenant;
  forceReadonly: boolean;
  dataBinding: DataBinding;
  dataTracker: DataTracker;

  public ok = false;
  data: ResultGetFranchiseeDetails | null = null;
  saved = false;

  get primaryContact(): BaseLogin | null | undefined {
    return this.tenantInfo?.primaryContact;
  }

  get deploymentId() {
    return this.options.view.deploymentId;
  }
  get tenantId() {
    return this.options.view.tenantId;
  }
  get inUse(): boolean {
    return (
      this.viewDeploymentTenants?.userLoginInformation?.find(x => x.lastLoginTime && x.tenantId === this.tenantId) !==
      undefined
    );
  }
  constructor(options: EditFranchiseeModalOptions) {
    super();
    this.options = options;
    this.forceReadonly = false;
    // franchisee databinding
    this.dataBinding = new DataBinding(this.ui, undefined, input => {
      return `franchisee-${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.input),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    const mockStatus = {
      status: getTenantStatusDisplayValue(this.options.view.status, this.options.view.inUse)
    };
    addField('status', FieldType.string, false, undefined, () => {
      return mockStatus;
    });
    addField('companyName', FieldType.string, false);
    addField('businessPhone', FieldType.string, false);
    addField('defaultV6Reference', FieldType.string, false);

    addField('availableLicenses', FieldType.int, false);
    addField('maxLicensePoolAllocation', FieldType.int, false);

    addField('friendlyName', FieldType.string, false, undefined, () => this.input.primaryContact);
    addField('emailAddress', FieldType.string, false, undefined, () => this.input.primaryContact);
    addField('jobTitle', FieldType.string, false, undefined, () => this.input.primaryContact);
    addField('phoneNumber', FieldType.string, false, undefined, () => this.input.primaryContact);
  }

  public async afterConstruction(): Promise<void> {
    await licenseServerUIBootLoader();
    this.data = await getApiFactory().franchisee().getFranchiseeDetails({
      deploymentFranchiseeConfigurationId: this.options.view.franchiseeDeploymentConfigId
    });
    this.viewDeploymentTenants = this.data?.tenantList.deployments?.find(
      x => x.dealerDeploymentId === this.deploymentId
    );
    this.tenantInfo = this.viewDeploymentTenants?.tenants?.find(x => x.tenantId === this.tenantId);
    this.tenant = this.data?.tenantList.tenants?.find(x => x.id === this.tenantId);
    this.input = {
      companyName: this.tenant?.companyName ?? '',
      businessPhone: this.tenant?.primaryContactPhoneNumber ?? '',
      globalSupplierSupplierId: userDataStore.globalSupplier.id,
      dealerDeploymentId: this.viewDeploymentTenants?.dealerDeploymentId ?? 0,
      tenantId: this.tenant?.id ?? 0,
      defaultV6Reference: this.tenantInfo?.reference ?? '',
      maxLicensePoolAllocation: this.tenantInfo?.licensePoolAllocations ?? 1,
      primaryContact: this.tenantInfo?.primaryContact ?? undefined,
      availableLicenses: this.viewDeploymentTenants?.licensePools?.[0].availableAllocations ?? 0
    };
    this.backup = clone(this.input);
    await this.loadOrRefresh();
  }

  protected async loadOrRefresh() {
    await this.render();
  }

  /**
   * inherited
   * @returns
   */
  protected async getTitle(): PromiseSnippet {
    return this.options.modalTitle() ?? '';
  }
  /**
   * inherited
   * @returns
   */
  protected modalSize(): string {
    return 'modal-xl';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    this.prepareForSave();
    if (compare(this.input, this.backup)) return true;

    const errors = await this.getValidationErrors();
    if (errors.length != 0) {
      const discardChanges = await showValidationsWithDiscard(errors);
      if (discardChanges) {
        this.dataTracker.resetEditorValue();
        return true;
      }
      return false;
    } else if (isAutoSaving()) return this.save();
    return true;
  }
  credentials: ILicenseServerApiCredentials = getCredentials();
  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    const forms1 = new FormInputAssistant(this.dataTracker, this.inUse);
    return html`
      <form class="py-3 px-0 frm-dealer-details form-two-col">
        <div class="row">
          <div>
            <h2>${tlang`%%franchisee%% Details`}</h2>
            ${forms.textReadonly('companyName', tlang`V6 Customer`)}
            ${forms.text('defaultV6Reference', tlang`%%v6-customer-code%%`)}
            ${forms1.text('businessPhone', tlang`Phone`)} ${forms1.textReadonly('status', tlang`Status`)}
            ${forms1.intReadonly('availableLicenses', tlang`Licenses Available`)}
            ${forms.int('maxLicensePoolAllocation', tlang`Licenses Allocated`)}
          </div>
          <div>
            <h2>${tlang`Primary %%contact%%`}</h2>

            <softtechls-username
              .readonly=${this.inUse}
              id="userName"
              .credentials=${this.credentials}
              .userName=${this.options.view.primaryContact?.userName}
              .existingUserName=${this.options.view.primaryContact?.userName}
            ></softtechls-username>

            ${forms1.text('friendlyName', tlang`Display Name`, 100)} ${forms1.text('emailAddress', tlang`Email`, 200)}
            ${forms1.text('jobTitle', tlang`Role`, 100)} ${forms1.text('phoneNumber', tlang`Mobile Phone`, 100)}
          </div>
        </div>
        <div class="row">
          <div></div>
        </div>
      </form>
    `;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      if (await this.isCheckingUsername()) return;
      this.prepareForSave();
      if (compare(this.input, this.backup)) {
        await this.hideModal();
        return;
      }
      const errors = await this.getValidationErrors();
      if (errors.length != 0) {
        const discardChanges = await showValidationsWithDiscard(errors);
        if (discardChanges) {
          this.dataTracker.resetEditorValue();
        }
      } else {
        if (await this.save()) await this.hideModal();
      }
    };
    return this.createConfirmCancelButtons(this.options.saveButtonTitle(), okEvent, this.options.cancelButtonTitle());
  }

  protected prepareForSave(): void {
    if (this.forceReadonly) return;
    this.dataTracker.applyChangeToValue();
    if (this.input.primaryContact) this.input.primaryContact.userName = this.editUserName?.['userName'] ?? '';
  }

  private async isCheckingUsername(showMsg = true) {
    if (this.editUserName?.['checkingUserName']) {
      if (showMsg) await information(tlang`Please wait for %%user%% name validation`);
      return true;
    }
    return false;
  }
  protected async save(): Promise<boolean> {
    if (await this.isCheckingUsername()) return false;
    const input: InputUpdateFranchiseeSupplierProperties = {
      defaultV6Reference: this.input.defaultV6Reference,
      deploymentFranchiseeConfigurationId: this.options.view.franchiseeDeploymentConfigId,
      maxLicensePoolAllocation: this.input.maxLicensePoolAllocation,
      primaryContact: this.input.primaryContact ?? null,
      primaryContactEmail: null,
      primaryContactPhoneNumber: this.input.businessPhone
    };
    const result = await getApiFactory().franchisee().updateFranchisee(input);

    this.saved = true;
    return result !== null;
  }
  get editUserName(): HTMLElement | null {
    return this.ui.querySelector('#userName');
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];
    if (await this.isCheckingUsername(false)) errors.push(tlang`Please wait for %%user%% name validation`);
    if (isEmptyOrSpace(this.input.defaultV6Reference)) {
      errors.push(tlang`%%v6-customer-code%% must be a valid value from your V6 System`);
    }
    if (this.input.maxLicensePoolAllocation < this.options.view.LicensesUsed) {
      errors.push(
        tlang`${'ref:lower-dealer-license-restriction'}The %%franchisee%% is using ${this.options.view.LicensesUsed} Licenses and the number cannot be reduced at this time below that. Please dicuss this requirement with them
                    directly and ask them to De-Activate users of the required amount first.`
      );
    }
    const maxLicenses = this.input.availableLicenses + (this.tenantInfo?.licensePoolAllocations ?? 0);
    if (this.input.maxLicensePoolAllocation > maxLicenses)
      errors.push(
        tlang`You may not allocate above your current available limit of ${maxLicenses} (being ${
          this.input.availableLicenses
        } + ${this.tenantInfo?.licensePoolAllocations ?? 0} )`
      );

    if (!this.inUse) {
      if (isEmptyOrSpace(this.input.primaryContact?.userName))
        errors.push(tlang`Please provide a %%user%% Name for the %%franchisee%%`);
    }

    return errors;
  }
}
