import { getApiFactory } from '../../../api/api-injector';
import { emptyGuid } from '../../../api/guid';
import { ResultGetGeneratedPurchaseOrderRevenue } from '../../../api/supplier-api-interface-franchiseenetwork';
import { compare } from '../../../components/clone';

class GeneratedPurchaseOrdersCache {
  _pageKey = this.pageKey('', '-1', []);
  _pagePromise: Promise<ResultGetGeneratedPurchaseOrderRevenue | null> | null = null;
  pageKey(
    startDate: string,
    endDate: string,
    branchIds: string[],
    userId?: string
  ): { startDate: string; endDate: string; branchIds: string[]; userId?: string } {
    return { startDate, endDate, branchIds, userId };
  }
  async getData(startDate: string, endDate: string, branchIds: string[], userId?: string) {
    const currentPage = this.pageKey(startDate, endDate, branchIds, userId);
    if (!compare(this._pageKey, currentPage)) {
      this._pageKey = currentPage;
      this._pagePromise = getApiFactory().dataAggregation().getGeneratedPurchaseOrderRevenue({
        startDate: startDate,
        endDate: endDate,
        branchIds: branchIds,
        assignedToUserId: userId ?? emptyGuid
      });
    }
    return await this._pagePromise;
  }
}

let _db: GeneratedPurchaseOrdersCache | null = null;
export function flushGeneratedPurchaseOrdersCache() {
  _db = null;
}
export function generatedPurchaseOrdersCache(): GeneratedPurchaseOrdersCache {
  if (!_db) {
    _db = new GeneratedPurchaseOrdersCache();
  }
  return _db;
}