import { constructAsync } from '../../../async-constructor';
import { SettingsPricingRulesView } from '../../../settings/view/settings-pricing-rules-view';
import { SettingsViewChildFactoryImpl } from '../../../settings/view/settings-view';
import { SupplierSettingsPricingRulesView } from './supplier-settings-pricing-rules-view';

export class SupplierSettingsViewChildFactoryImpl extends SettingsViewChildFactoryImpl {
  async getPricingRulesView(): Promise<SettingsPricingRulesView> {
    return await constructAsync(new SupplierSettingsPricingRulesView());
  }
}
