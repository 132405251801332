import { claimIdentity, tlang } from '@softtech/webmodule-components';
import { DataEntryPageControlView } from '../../components/ui/data-entry-screen-base';
import {
  PageControl,
  PageManager,
  MenuIconOption,
  PageControlOptions,
  PageButtonLocation
} from '../../components/ui/page-control';
import { constructAsync } from '../../async-constructor';
import { getApiFactory } from '../../api/api-injector';

import { userDataStore } from '../../supplier/common/current-user-data-store';
import { FranchiseeNetworkApi } from '../../api/franchisee-network-api';

import {
  SupportInboxItemStatus,
  SupportInboxItemType,
  ViewSupportInboxItemEx
} from '../../api/supplier-api-interface-franchiseenetwork';
import { information } from '../../components/ui/modal-option';
import { QuoteSupportInboxViewTab } from './quote-support-inbox-view-tab';
import { lockUIandExecute } from '../../ui-lock';
import { getSupportTypeDisplayValue } from '../data/support-helper';

export class SupportInboxView extends DataEntryPageControlView {
  protected franchiseeApi: FranchiseeNetworkApi = getApiFactory().franchisee();

  protected viewAll?: QuoteSupportInboxViewTab;
  protected viewReviews?: QuoteSupportInboxViewTab;
  protected viewSupport?: QuoteSupportInboxViewTab;
  protected viewCustomItems?: QuoteSupportInboxViewTab;
  protected viewArchived?: QuoteSupportInboxViewTab;

  public async afterConstruction(): Promise<void> {
    this.viewAll = await constructAsync(
      new QuoteSupportInboxViewTab({
        tabName: 'all',
        caption: tlang`Current (All)`,
        statusFilter:
          SupportInboxItemStatus.Active |
          SupportInboxItemStatus.ReActivated |
          SupportInboxItemStatus.New |
          SupportInboxItemStatus.Overdue,
        typeFilter: SupportInboxItemType.All,
        eventLogin: item => this.loginAsFranchisee(item),
        showAssignedTo: true,
        showLastModified: true
      })
    );
    this.viewReviews = await constructAsync(
      new QuoteSupportInboxViewTab({
        tabName: 'quotereviews',
        caption: tlang`%%quote%% Reviews`,
        statusFilter:
          SupportInboxItemStatus.Active |
          SupportInboxItemStatus.New |
          SupportInboxItemStatus.ReActivated |
          SupportInboxItemStatus.Overdue,
        typeFilter: SupportInboxItemType.QuoteReview,
        eventLogin: item => this.loginAsFranchisee(item),
        showAssignedTo: true,
        showLastModified: true
      })
    );
    this.viewSupport = await constructAsync(
      new QuoteSupportInboxViewTab({
        tabName: 'quotesupport',
        caption: `${getSupportTypeDisplayValue(SupportInboxItemType.QuoteAssistanceRequest)}s`,
        statusFilter:
          SupportInboxItemStatus.Active |
          SupportInboxItemStatus.New |
          SupportInboxItemStatus.ReActivated |
          SupportInboxItemStatus.Overdue,
        typeFilter: SupportInboxItemType.QuoteAssistanceRequest,
        eventLogin: item => this.loginAsFranchisee(item),
        showAssignedTo: true,
        showLastModified: true
      })
    );
    this.viewCustomItems = await constructAsync(
      new QuoteSupportInboxViewTab({
        tabName: 'customquoteitem',
        caption: `${getSupportTypeDisplayValue(SupportInboxItemType.EngineeredToOrder)}s`,
        statusFilter:
          SupportInboxItemStatus.Active |
          SupportInboxItemStatus.New |
          SupportInboxItemStatus.ReActivated |
          SupportInboxItemStatus.Overdue,
        typeFilter: SupportInboxItemType.EngineeredToOrder,
        eventLogin: item => this.loginAsFranchisee(item),
        showAssignedTo: true,
        showLastModified: true
      })
    );
    this.viewArchived = await constructAsync(
      new QuoteSupportInboxViewTab({
        tabName: 'archived',
        caption: tlang`Archived`,
        statusFilter: SupportInboxItemStatus.Cancelled | SupportInboxItemStatus.Resolved,
        typeFilter: SupportInboxItemType.All,
        eventLogin: item => this.loginAsFranchisee(item),
        showResolvedColumns: true
      })
    );

    await super.afterConstruction();
    this.pageControl.pageButtonLocation = PageButtonLocation.right;
  }

  protected getPagesForPageControl(): PageManager[] {
    const list: PageManager[] = [];

    if (this.viewAll) list.push(this.viewAll.createPageManager());
    if (this.viewReviews) list.push(this.viewReviews.createPageManager());
    if (this.viewSupport) list.push(this.viewSupport.createPageManager());
    if (this.viewCustomItems) list.push(this.viewCustomItems.createPageManager());
    if (this.viewArchived) list.push(this.viewArchived.createPageManager());
    return list;
  }

  async createPageControl(): Promise<PageControl> {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.getPagesForPageControl();
    };
    const menu: MenuIconOption[] = [];
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: menu,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  async prepareForSave(): Promise<void> {
    //DOES NOTHING HANDLED BY CHILDREN
  }

  public internalDataChanged(): boolean {
    return false;
  }

  protected async loginAsFranchisee(item: ViewSupportInboxItemEx) {
    await lockUIandExecute(async () => {
      const claims = {};
      //this sets the branch they will navigate to
      claims[claimIdentity.branchId] = item.item.branchId;
      claims[claimIdentity.frameConfigPowerUser] = 'true';
      //indicates supplier level privelages
      claims[claimIdentity.agent] = 'true';
      //allow suppliers to administer dealer staff.
      claims[claimIdentity.admin] = 'true';

      const result = await this.franchiseeApi.pat({
        preferredLifeOfTokenHours: 8,
        tenantId: item.item.franchiseeTenantId,
        dealerDeploymentId: item.item.dealerDeploymentId,
        claims: claims
      });

      const deployment = userDataStore.allFranchiseeDetails.tenantList.deployments?.find(
        x => x.dealerDeploymentId === item.item.dealerDeploymentId
      );
      //https://www.kuaka-testing.softtech.com/quotes/03109e02-0353-4e05-af96-c029b3559290
      if (!deployment) {
        await information(tlang`Cannot resolve the link for this data`);
      }
      const url = `${deployment?.uiDomain}/quotes/${item.item.quoteId}`;
      const fragment =
        item.item.type == SupportInboxItemType.QuoteAssistanceRequest ||
        item.item.type == SupportInboxItemType.EngineeredToOrder
          ? `#support_${item.item.conversationId}`
          : '';

      if (result) {
        window.open(`${url}?PAT=${result.pat}${fragment}`);
      }
    });
  }
}
