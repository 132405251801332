import { html } from 'lit';
import { IconRefresh } from './icons/icon-refresh';

import type { TemplateResult } from 'lit';
import { sleep } from '../time';

export type CommandActionEvent = (() => Promise<void>) | (() => void);
export interface ICommandAction {
  label: string | TemplateResult;
  event: CommandActionEvent;
  enabled: boolean;
}

export interface ActionButtonOptions {
  btnClass?: string;
}

export function commandActionButton(action: ICommandAction, options?: ActionButtonOptions) {
  const btnClass = options?.btnClass ?? 'btn-primary';
  return html`<button class="btn ${btnClass}" ?disabled=${!action.enabled} @click=${action.event}>
    ${action.label}
  </button>`;
}

export function delayedEvent(event: CommandActionEvent, delayMS?: number) {
  if (!delayMS || delayMS === 0) return event;
  return async () => {
    const time = new Date();
    await event();
    const time1 = new Date();
    const msDiff = time1.getTime() - time.getTime();
    if (msDiff < delayMS) await sleep(delayMS - msDiff);
  };
}
export function refreshButton(executeEvent: () => Promise<void>, options?: ActionButtonOptions) {
  return commandActionButton(new RefreshCommandAction(delayedEvent(executeEvent, 2000)), options);
}

export class RefreshCommandAction implements ICommandAction {
  private icon: IconRefresh = new IconRefresh();
  private executeEvent: CommandActionEvent;

  constructor(executeEvent: CommandActionEvent) {
    this.executeEvent = executeEvent;
  }

  get label(): TemplateResult {
    return html`${this.icon}`;
  }
  event = async (): Promise<void> => {
    this.icon.active = true;
    try {
      await this.executeEvent();
    } finally {
      this.icon.active = false;
    }
  };
  enabled = true;
}
