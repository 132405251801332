import { html, TemplateResult } from 'lit';
import { EventSnippet, PromiseSnippet, PromiseTemplate } from './events';
import { ModalDialog } from './modal-base';

export class WaitPatiently extends ModalDialog {
  title: EventSnippet;
  message: EventSnippet;

  constructor(title: EventSnippet, message: EventSnippet) {
    super();
    this.title = title;
    this.message = message;

    // we dont want to display things that are fast and flicker.. so wait before showing a dialog.
    setTimeout(() => {
      if (!this._hiding) this.showModal();
    }, 400);
  }

  //remove closebutton
  protected closeButtonTemplate(): TemplateResult {
    return html``;
  }

  public async hideModal(): Promise<void> {
    try {
      await super.hideModal();
    } catch {
      //ignore errors here. we might not have a modal to close
    }
  }
  protected modalSize(): string {
    return 'modal-md';
  }

  protected async getTitle(): PromiseSnippet {
    return this.title();
  }
  protected async bodyTemplate(): PromiseTemplate {
    return html`
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height:3rem;">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="alert alert-light text-center" role="alert">${this.message()}</div>
    `;
  }
}
