import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

export class InputElement extends LitElement {
  private _value: string = '';

  @property() classes = '';
  @property() readonly = false;
  @property() disabled = false;
  @property() inputType = '';
  @property() min = null;
  @property() max = null;

  get value(): string {
    return this._value;
  }
  set value(value) {
    const previousValue = this._value;
    this._value = value;
    this.requestUpdate('value', previousValue);
  }

  onChange(e: any) {
    this.value = e.target.value;
  }

  render() {
    return html`<input
      .type=${this.inputType}
      class=${this.classes}
      @change=${this.onChange}
      .value=${this.value}
      ?readonly=${this.readonly}
      ?disabled=${this.disabled}
      min="${ifDefined(this.min)}"
      max="${ifDefined(this.max)}"
    />`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
