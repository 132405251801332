import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('icon-checked')
export class IconChecked extends LitElement {
  render() {
    return html`<i class="fa-solid fa-circle-check"></i>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
