import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { marked } from 'marked';
import { getApiFactory } from '../../api/api-injector';
import { isAutoSaving } from '../../components/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { fireQuickSuccessToast } from '../../toast-away';
import { ImageUploader, VirtualFile } from '../data/image-upload';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';

export class SettingsLogoBrandingView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;
  private imageUploader: ImageUploader;

  private positiveLogoName = 'supplier-positive-logo';
  private negativeLogoName = 'supplier-negative-logo';

  constructor() {
    super();
    this.pageFragment = 'branding';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.imageUploader = new ImageUploader({
      dataBinding: this.dataBinding
    });
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Branding`;
  }
  getValidationErrors(): string[] {
    const errors: string[] = [];

    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (
      this.imageUploader.elementHasFile(this.positiveLogoName) &&
      !this.imageUploader.elementHasImageFile(this.positiveLogoName)
    ) {
      errors.push(tlang`Please provide a valid Color Logo`);
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (
      this.imageUploader.elementHasFile(this.negativeLogoName) &&
      !this.imageUploader.elementHasImageFile(this.negativeLogoName)
    ) {
      errors.push(tlang`Please provide a valid White Logo`);
    }
    return errors;
  }

  async prepareForSave(): Promise<void> {
    //nothing to do;
  }
  public internalDataChanged(): boolean {
    return (
      this.imageUploader.elementHasFile(this.positiveLogoName) ||
      this.imageUploader.elementHasFile(this.negativeLogoName)
    );
  }

  async onEnter(): Promise<void> {
    await this.render();
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a logo from the given element and get its uploaded file path
    const newPositiveLogo = await this.getBase64LogoFromElement(this.positiveLogoName);
    const newNegativeLogo = await this.getBase64LogoFromElement(this.negativeLogoName);

    this.settingsManager.updateInput.base64PositiveLogo = newPositiveLogo?.content ?? null;
    this.settingsManager.updateInput.base64NegativeLogo = newNegativeLogo?.content ?? null;
    this.settingsManager.updateInput.positiveLogoFileName = newPositiveLogo
      ? `positiveBrandingLogo${newPositiveLogo?.extension}`
      : null;
    this.settingsManager.updateInput.negativeLogoFileName = newNegativeLogo
      ? `negativeBrandingLogo${newNegativeLogo?.extension}`
      : null;

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Branding saved`);
      await this.render();
      return true;
    }
    return false;
  }

  /**
   * Attempts to upload a logo image from the specified element and returns its virtual file path if successful, or returns null if there is no logo to upload.
   * If successful, it removes the local file from the specified element.
   * @param fieldName The field name of the element that the file will be uploaded from.
   * @param newFileName The new name of the uploaded file.
   * @returns A promise with the file path if the file was uploaded, or null if it wasn't.
   */
  private async getBase64LogoFromElement(fieldName: string): Promise<VirtualFile | null> {
    const fileInformation = await this.imageUploader.getVirtualFileFromElement(fieldName);
    // only attempt an upload if we have information about the image file to be uploaded.
    if (fileInformation) {
      this.dataBinding.removeFiles(fieldName);
      // get the virtual logo path from the API and attempt to upload the file content.
      return fileInformation;
    }
    // if we get here no logo image has been uploaded, so return null as the path.
    return null;
  }

  /**
   * Attempts to get the absolute virtual file path for a given relative file path.
   * @param relativeVirtualFilePath The relative virtual file path.
   * @returns The full absolute virtual file path.
   */
  private getAbsoluteVirtualFilePath(relativeVirtualFilePath: string, alternateFilePath: string = ''): string {
    if (!isEmptyOrSpace(relativeVirtualFilePath)) return getApiFactory().blob().fullUrl(relativeVirtualFilePath);
    else return alternateFilePath;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const fld = (name: string) => this.dataBinding.field(name);

    const brandingMsg = marked.parse(
      tlang`${'ref:FranchiseeBrandingView:uploadMsg:1'}
Please upload your business logo<br>
This will be incorporated into documents sent to !!client!! and !!franchisee!!, such as !!estimate!!/!!invoice!!/!!purchase-order!!.

Choose a logo to appear at the top of PDFs and reports you print or send.<br>
The minimum permitted size is **800(W) x 400(H)** pixels.<br>
The ideal size of your logo is **1600(W) x 800(H)** pixels.<br>
The image must be **300DPI** in quality and in the **jpg** format on a white background.<br>
If the aspect ratio of your logo is different, it will be scaled down in proportion to fit the template constraints.
`,
      { async: false }
    ) as string;

    const positiveBrandingLogo = this.getAbsoluteVirtualFilePath(
      this.settings.positiveLogoVirtualPath,
      './assets/images/sample-logo-colour.png'
    );
    const negativeBrandingLogo = this.getAbsoluteVirtualFilePath(
      this.settings?.negativeLogoVirtualPath,
      './assets/images/sample-logo-white.png'
    );

    return html` <div>
      <form id="CompanyBrandingForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">${tlang`Colour Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on the top left hand corner of your masthead`}
            </div>
            <bs-form-image-upload
              data-id=${fld(this.positiveLogoName)}
              .value="${positiveBrandingLogo}"
              data-label=${tlang`Colour Logo`}
            ></bs-form-image-upload>
          </div>
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">${tlang`White Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on printed reports ( PDF ) and the footer of the %dealership%`}
            </div>
            <bs-form-image-upload
              data-id=${fld(this.negativeLogoName)}
              .value="${negativeBrandingLogo}"
              data-label=${tlang`White Logo`}
            ></bs-form-image-upload>
          </div>
        </div>
        <h2>${tlang`Specifications`}:</h2>
        <p>${unsafeHTML(brandingMsg)}</p>
      </form>
    </div>`;
  }
}
