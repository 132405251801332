import { BaseModal, tlang } from '@softtech/webmodule-components';
import { html, LitElement, PropertyValueMap, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { clone } from '../../components/clone';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { BaseLogin, DeploymentFranchiseeBranch, Tenant } from '../../api/supplier-api-interface-franchiseenetwork';
import {
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../../components/save-workflow';
import { showDevelopmentError } from '../../development-error';
import { checkValidations } from '../../components/ui/data-entry-screen-helpers';
import { getApiFactory } from '../../api/api-injector';
import { FranchiseeBranchDeploymentView } from '../data/franchisee-branch-deployment-view';
import { userDataStore } from '../../supplier/common/current-user-data-store';
import { information } from '../../components/ui/modal-option';
import { createConfirmCancelButtons } from '../../components/ui/modal-base';

type Branch = DeploymentFranchiseeBranch;

@customElement('webmodule-supplier-franchisee-branch-editor')
export class WebModuleFranchiseeBranchEditor extends LitElement {
  @query('#branchReference')
  branchReferenceEditor?: HTMLInputElement;
  @property() tenant?: Tenant;
  @property() primaryContact?: BaseLogin;
  @property()
  private _branch?: Branch | undefined;

  public get branch(): Branch | undefined {
    return this._branch;
  }
  public set branch(value: Branch | undefined) {
    this._branch = clone(value);
  }
  @property() readonly = false;

  @property()
  private _branchReference = '';
  public get branchReference() {
    return this._branchReference;
  }
  public set branchReference(value) {
    this._branchReference = value;
  }
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branch),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    const tenantData = () => this.tenant;
    const addTenantField = (fieldName: string, propertyType?: FieldType, nullable?: boolean) => {
      this.dataTracker.addObjectBinding(
        tenantData,
        fieldName,
        fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    const contactData = () => this.primaryContact;
    const addContactField = (fieldName: string) => {
      this.dataTracker.addObjectBinding(contactData, fieldName, fieldName, FieldType.string, false);
    };

    addContactField('userName');
    addContactField('friendlyName');
    addContactField('emailAddress');
    addContactField('jobTitle');
    addContactField('phoneNumber');

    addTenantField('companyName', FieldType.string, false);
    addTenantField('primaryContactPhoneNumber', FieldType.string, false);

    const mockstatus = { status: tlang`Active` };
    addField('status', FieldType.string, false, undefined, () => mockstatus);

    addField('name', FieldType.string, false);
    addField('taxRegistrationNumber', FieldType.string, false);
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    setTimeout(() => {
      const editor = this.branchReferenceEditor;
      if (editor) {
        editor.focus();
        editor.select();
      }
    }, 200);
  }
  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-sbe-${name}`, options));
  }
  branchChangedEvent() {
    this.dispatchCustom('changed', {
      branch: this.branch,
      branchReference: this.branchReference
    });
  }
  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();

    this.dataTracker.applyChangeToValue();
    this.branchReference = this.branchReferenceEditor?.value ?? '';
    this.branchChangedEvent();
  };
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }
  render() {
    const forms = new FormInputAssistant(this.dataTracker, true);

    if (!this.branch) return html``;
    return html`<div class="form-two-col p-3 branch-form">
      <div class="row">
        <div>
          <h2>${tlang`%%franchisee%% Details`}</h2>
          <div>
            ${forms.text('companyName', tlang`%%franchisee%% Name`, 100)} ${forms.text('name', tlang`Branch Name`, 100)}
            ${forms.text('taxRegistrationNumber', tlang`Tax Registration #:`, 100)}
            <div class="row mb-2 align-items-center form-col-item">
              <label class="form-col-label" for="">${tlang`V6 Customer Code:`}</label>
              <div class="form-col-input">
                <input
                  class="form-control"
                  id="branchReference"
                  .value=${this.branchReference}
                  type="text"
                  maxlength="20"
                />
              </div>
            </div>
            ${forms.text('primaryContactPhoneNumber', tlang`Business Phone`, 100)}
            ${forms.text('status', tlang`Status`, 100)}
          </div>
        </div>
        <div>
          <h2>${tlang`Primary %%contact%%`}</h2>
          ${forms.text('userName', tlang`User Name`, 100)} ${forms.text('friendlyName', tlang`Display Name`, 100)}
          ${forms.text('emailAddress', tlang`Email`, 200)} ${forms.text('jobTitle', tlang`Role`, 100)}
          ${forms.text('phoneNumber', tlang`Mobile Phone`, 100)}
        </div>
      </div>
      <div class="row"></div>
      <webmodule-address-editor
        id="physicalAddressEditor"
        .address=${this.branch.physicalAddress}
        .title=${tlang`Branch Address`}
        .readonly=${true}
        .isDefaultShippingVisible=${true}
        .isDefaultShipping=${this.branch.physicalAddressAsDefaultShipping}
      ></webmodule-address-editor>
      <webmodule-address-editor
        id="physicalAddressEditor"
        .address=${this.branch.billingAddress}
        .title=${tlang`Billing Address`}
        .readonly=${true}
        .isDefaultShippingVisible=${false}
        .isSameAsOtherAddressVisible=${true}
        .isSameAsOtherAddress=${!this.branch.billingAddress}
      ></webmodule-address-editor>
    </div> `;
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}

export async function editBranch(item: FranchiseeBranchDeploymentView) {
  await userDataStore.loadCoreDetails();
  const data = userDataStore.allFranchiseeDetails.branchReferences.find(
    x => x.tenantId === item.tenantId && x.dealerDeplymentId === item.deploymentId
  );

  const branchReference = data?.referenceOverrides?.[item.branchId] ?? item.reference;
  const branch = userDataStore.allFranchiseeDetails.branches.find(x => x.branchId === item.branchId);
  if (branch) {
    const dialog = new BranchEditDialog(item, branch, branchReference);
    await dialog.showModal();
    return dialog.saved;
  } else {
    await information(tlang`Could not locate %%branch%% data`);
    return false;
  }
}

class BranchEditDialog extends BaseModal {
  abandoned: any;
  branch: Branch;
  branchReference: string;
  item: FranchiseeBranchDeploymentView;
  backupBranchReference: string;
  saved = false;
  get currentBranch(): Branch {
    return this.branch;
  }
  protected modalSize(): string {
    return 'modal-fullscreen';
  }
  constructor(item: FranchiseeBranchDeploymentView, branch: Branch, branchReference: string) {
    super();
    this.item = item;
    this.backupBranchReference = branchReference;
    this.branchReference = branchReference;
    this.branch = branch;
  }
  async title(): Promise<string | TemplateResult> {
    return tlang`Edit %%branch%%`;
  }
  public getValidationErrors(): string[] {
    const errors: string[] = [];

    return errors;
  }

  updateCurrentBranch(branchReference: string) {
    this.branchReference = branchReference;
  }
  async bodyTemplate(): Promise<TemplateResult> {
    const branchChangedEvent = (e: CustomEvent<{ branch: Branch; branchReference: string }>) => {
      e.stopImmediatePropagation();
      this.updateCurrentBranch(e.detail.branchReference);
    };
    return html` <webmodule-supplier-franchisee-branch-editor
      .primaryContact=${this.item.primaryContact}
      .tenant=${userDataStore.allFranchiseeDetails.tenantList.tenants?.find(x => x.id === this.item.tenantId)}
      .branch=${this.currentBranch}
      .branchReference=${this.branchReference}
      @wm-sbe-changed=${branchChangedEvent}
    ></webmodule-supplier-franchisee-branch-editor>`;
  }
  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }

  async performAutoSave(options: SavePromptOptions): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await options.needsSaveEvent();
    } catch (e) {
      await showDevelopmentError(e as Error);
      console.log((e as object).toString());
      return false;
    }

    //this is to let us abandon new items that have had no editing
    if (!needsSave) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);

    if (!(await this.checkValidations())) return false;
    if (isAutoSaving() && !needsSave) return true;

    //this is our basic save indicator
    return await saveWithIndicator(async () => await this.internalSaveData());
  }
  async internalSaveData() {
    const referenceOverrides = {};
    referenceOverrides[this.item.branchId] = this.branchReference;
    await getApiFactory().franchisee().updateTenantCustomerCodes({
      dealerDeploymentId: this.item.deploymentId,
      tenantId: this.item.tenantId,
      referenceOverrides: referenceOverrides
    });
    this.saved = true;
    const refs = userDataStore.allFranchiseeDetails.branchReferences.find(
      x => x.dealerDeplymentId == this.item.deploymentId && x.tenantId == this.item.tenantId
    );
    if (refs) {
      if (refs.referenceOverrides) refs.referenceOverrides[this.item.branchId] = this.branchReference;
      else refs.referenceOverrides = referenceOverrides;
    }

    return true;
  }
  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: false,
      autoSaveEvent: async options => {
        return await this.performAutoSave(options);
      },
      dictionaryName: '%%branch%%',
      needsSaveEvent: async () => this.branchReference !== this.backupBranchReference,
      abandonSaveEvent: async () => {
        this.abandoned = true;
        this.branchReference = this.backupBranchReference;
        return true;
      },
      displaySaveModal: false,
      informationDispatcher: undefined
    };
  }

  async canClose(): Promise<boolean> {
    return this.abandoned || (await canClose(this.getAutoSavePromptOptions()));
  }

  footerTemplate(): TemplateResult | null | undefined {
    const okEvent = async () => {
      if (await this.canClose()) {
        await this.hideModal();
        return;
      }
    };
    const closeEvent = async() => await this.hideModal();
    return createConfirmCancelButtons(tlang`Save Changes`, okEvent, tlang`Cancel`, closeEvent);
  }

  get isFooterVisible(): boolean {
    return true;
  }
}
