import { InputElement } from './input-edit';
import { InputBoolean } from './input-boolean';
import { InputTriCheck } from './input-tri-check';

let registered = false;

function safeRegister(name: string, constructor: CustomElementConstructor) {
  try {
    customElements.define(name, constructor);
  } catch {
    console.log(`customElement "${name}" already registered`);
  }
}

function prefixName(name: string, prefix?: string): string {
  if (prefix) prefix += '-';
  else prefix = 'softtech-sui-';
  return `${prefix}${name}`;
}

declare global {
  interface HTMLElementTagNameMap {
    'softtech-sui-input-edit': InputElement;
    'softtech-sui-input-boolean': InputBoolean;
    'softtech-sui-input-tricheck': InputTriCheck;
  }
}

export function registerComponentsLocal(prefix?: string) {
  if (!registered) {
    safeRegister(prefixName('input-edit', prefix), InputElement);
    safeRegister(prefixName('input-boolean', prefix), InputBoolean);
    safeRegister(prefixName('input-tricheck', prefix), InputTriCheck);
    registered = true;
  }
  /* plop:component */
}
