import { WebModuleAssignUserEdit } from '../../dealer/view/assignto-edit';
import { WebModuleQuoteSupportInboxView } from '../../dealer/view/quote-support-inbox-view';
import { WebModuleFranchiseeNetwork } from '../../dealer/view/tenant-network-view';
import { TerritoryFranchiseeTableElement } from '../../settings/ui/territory-franchisee-table-element';
import { WebModuleAddressEditor } from './address-editor';
import { SettingsLocationsTable } from '../../settings/view/settings-locations-view';
import { SettingsPoRecipientsTable } from '../../settings/view/settings-purchase-orders-view';

export const webcomponentRegistry = {
  addressEditor: WebModuleAddressEditor,
  webModuleFranchiseeNetwork: WebModuleFranchiseeNetwork,
  webModuleQuoteSupportInboxView: WebModuleQuoteSupportInboxView,
  webModuleAssignUserEdit: WebModuleAssignUserEdit,
  territoryFranchiseeTableElement: TerritoryFranchiseeTableElement,
  settingsLocationsTable: SettingsLocationsTable,
  settingsPoRecipientsTable: SettingsPoRecipientsTable
};
