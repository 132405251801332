import { tlang } from '@softtech/webmodule-components';
import { Address } from '@softtech/webmodule-data-contracts';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { googleMapApiKey } from '../../api/google-api';
import { Place } from './maps/place';
import { emptyAddress, mapTemplate, updateAddress } from './maps/map-helpers';
import { DataBinding } from './databinding/databinding';
import { DataTracker, FieldType } from './databinding/data-tracker';
import { FormInputAssistant } from './templateresult/form-input-assistant';
import { tryParseFloat } from '../number-utilities';
import { clone, compare } from '../clone';
import { bsFormRadioGroupYesNoOptions, displayYesNo } from './helper-functions';

@customElement('webmodule-address-editor')
export class WebModuleAddressEditor extends LitElement {
  private _backupAddress?: Address;
  @property() displayMap = true;
  @property() displayLookup = true;
  @property() isSameAsOtherAddressVisible = false;
  @property()
  private _isSameAsOtherAddress = false;
  public get isSameAsOtherAddress() {
    return this._isSameAsOtherAddress;
  }
  public set isSameAsOtherAddress(value) {
    this._isSameAsOtherAddress = value;
    if (value) this._address = undefined;
    else if (!this._address) this._address = emptyAddress();
    this.addressChangedEvent();
  }
  @property()
  private _address?: Address | undefined;

  public get address(): Address | undefined {
    return this._address;
  }
  public set address(value: Address | undefined) {
    this._address = clone(value);
    this._backupAddress = clone(value);
  }
  @property() subheader?: object;
  @property() title = 'Address';
  @property() readonly = false;
  @property() isDefaultShippingVisible = false;
  @property()
  private _isDefaultShipping = false;
  public get isDefaultShipping() {
    return this._isDefaultShipping;
  }
  public set isDefaultShipping(value) {
    this._isDefaultShipping = value;
    this.addressChangedEvent();
  }
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.address),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('line1', FieldType.string, false);
    addField('locality', FieldType.string, true);
    addField('region', FieldType.string, true);
    addField('postcode', FieldType.string, false);
    addField('country', FieldType.string, true);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    this.dispatchEvent(new CustomEvent(`wm-ae-${name}`, options));
  }
  addressChangedEvent() {
    this.dispatchCustom('changed', {
      address: this.address,
      isDefaultShipping: this.isDefaultShippingVisible ? this.isDefaultShipping : undefined,
      isSameAsOtherAddress: this.isSameAsOtherAddress
    });
  }
  private get addressDetailsVisible(): boolean {
    return (this.isSameAsOtherAddressVisible && !this.isSameAsOtherAddress) || !this.isSameAsOtherAddressVisible;
  }
  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();
    if (this.addressDetailsVisible) this.dataTracker.applyChangeToValue();
    if (!compare(this._address, this._backupAddress)) {
      this._backupAddress = clone(this._address);
      this.requestUpdate();
      this.addressChangedEvent();
    }
  };
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }
  render() {
    const formsPhysical = new FormInputAssistant(this.dataTracker, this.readonly);

    const updateAddressEvent = _ => this.updateAddressFromMapHelper(_.detail.place);
    const defaultShippingEvent = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      this.isDefaultShipping = e.detail;
    };
    const defaultShippingTemplate = this.isDefaultShippingVisible
      ? html`<bs-form-checkbox
          data-label=${tlang`Default Shipping Address`}
          ?readonly=${this.readonly}
          data-id=${this.dataTracker.binder.field('physicalAddressAsDefaultShipping')}
          .checked=${this.isDefaultShipping}
          @checkbox-changed=${defaultShippingEvent}
        >
        </bs-form-checkbox>`
      : html``;

    const sameAsOtherChangeEvent = (e: CustomEvent<string>) => {
      e.stopImmediatePropagation();
      this.isSameAsOtherAddress = e.detail === 'Yes';
    };
    const sameAsOtherAddressTemplate = this.isSameAsOtherAddressVisible
      ? html`<div>
          <bs-form-radio-group
            data-label=${tlang`Same as Physical Address`}
            data-id=${this.dataTracker.binder.field('sameAsOtherAddress')}
            value=${displayYesNo(this.isSameAsOtherAddress)}
            options=${bsFormRadioGroupYesNoOptions()}
            @radio-changed=${sameAsOtherChangeEvent}
          >
          </bs-form-radio-group>
        </div>`
      : html``;

    const lookupTemplate = this.displayLookup
      ? html`
          <google-place-autocomplete
            class="left right"
            data-id="clientauto"
            outline
            ?disabled=${this.readonly}
            searchType=${'address'}
            .apiKey=${googleMapApiKey}
            label=${tlang`Lookup`}
            @place-changed=${updateAddressEvent}
          >
          </google-place-autocomplete>
        `
      : html``;
    const mapViewTemplate = this.displayMap
      ? mapTemplate(tryParseFloat(this.address?.latitude), tryParseFloat(this.address?.longitude))
      : html``;
    const subheader = this.subheader ?? html``;
    const primaryDetailsTemplate = this.addressDetailsVisible
      ? html`
          <div class="row">
            <div>
              ${subheader} ${sameAsOtherAddressTemplate} ${lookupTemplate}
              ${formsPhysical.textRequired('line1', tlang`Street Address`, 120)}
              ${formsPhysical.text('locality', tlang`City`, 120)} ${formsPhysical.text('region', tlang`State`, 120)}
              ${formsPhysical.textRequired('postcode', tlang`Zip Code`, 20)}
              ${formsPhysical.text('country', tlang`Country`, 80)} ${defaultShippingTemplate}
            </div>
            <div class="address-map">${mapViewTemplate}</div>
          </div>
        `
      : html`
          <div class="row">
            <div>${sameAsOtherAddressTemplate}</div>
            <div class=""></div>
          </div>
        `;

    return html`
      <div class="form-two-col">
        <h2>${this.title}</h2>

        ${primaryDetailsTemplate}
      </div>
    `;
  }
  updateAddressFromMapHelper(place: Place) {
    if (!this.address || !place) return;
    updateAddress(place, this.address);
    this.addressChangedEvent();
    //manually trigger as we modified the content of a property, but not the property itself.
    this.requestUpdate();
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
