import { customElement, property } from 'lit/decorators.js';
import { LitElementBase } from '../../../components/litelement-base';
import { TemplateResult, html } from 'lit';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { emptyGuid } from '../../../api/guid';
import { tlang } from '@softtech/webmodule-components';

export interface DateRange {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

export interface DashboardFilterOptions {
  dateRange: DateRange | undefined;
  branchIds: string[] | undefined;
  userId: string | undefined;
  forceEmptyDisplay: boolean;
}

export interface DashboardFilterOption {
    id: string;
    name: string;
}

@customElement('wm-dashboard-filter')
export class DashboardFilter extends LitElementBase {
  @property() public dates?: DateRange[];
  @property() public territories?: DashboardFilterOption[];
  @property() public franchisees?: DashboardFilterOption[];
  @property() public branches?: DashboardFilterOption[];
  @property() public users?: DashboardFilterOption[];

  @property() public selectedDate?: DateRange;
  @property() public selectedTerritoryId?: string;
  @property() public selectedFranchiseeId?: string;
  @property() public selectedBranchId?: string;
  @property() public selectedUserId?: string;

  protected dateChangeEvent(e: Event) {
    const dateRangeId = (e.currentTarget as HTMLSelectElement).value;
    const dateRange = this.dates?.find(dr => dr.id == dateRangeId);
    this.dispatchCustom('date-changed', {
      date: dateRange
    });
  }

  protected territoryChangeEvent(e: Event) {
    const territoryId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('territory-changed', {
      territoryId: territoryId
    });
  }

  protected franchiseeChangeEvent(e: Event) {
    const franchiseeId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('franchisee-changed', {
      franchiseeId: franchiseeId
    });
  }

  protected branchChangeEvent(e: Event) {
    const branchId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('branch-changed', {
      branchId: branchId
    });
  }

  protected userChangeEvent(e: Event) {
    const userId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('user-changed', {
      userId: userId
    });
  }

  protected sendResetFilterEvent(_e: Event) {
    this.dispatchCustom('reset-filters', {});
  }

  protected render(): TemplateResult {
    const dateRangeTemplate = (dateRangeId: string) =>
      this.dates?.map(b => {
        return html`<option ?selected=${b.id === dateRangeId} value=${b.id}>${b.label}</option>`;
      });
    
    const isTerritorySelected = !isEmptyOrSpace(this.selectedTerritoryId);
    const isFranchiseeSelected  =!isEmptyOrSpace(this.selectedFranchiseeId);
    const territoryTemplate = (territoryId: string) => 
      this.territories?.map(t => {
        return html`<option ?selected=${t.id === territoryId} value=${t.id}>${t.name}</option>`;
      });
    const allTerritoriesOption = (this.territories && this.territories.length > 1)
      ? html`<option value=${emptyGuid} ?selected=${this.selectedTerritoryId === emptyGuid}>${tlang`All !!territory!!`}</option>`
      : html``;
    const isTerritoryDisabled = isFranchiseeSelected;

    const franchiseeTemplate = (franchiseeId: string) => 
      this.franchisees?.map(f => {
        return html`<option ?selected=${f.id === franchiseeId} value=${f.id}>${f.name}</option>`;
      });
    const allFranchiseesOption = (this.franchisees && this.franchisees.length > 1)
      ? html`<option value=${emptyGuid} ?selected=${this.selectedFranchiseeId === emptyGuid}>${tlang`All !!franchisee!!`}</option>`
      : html``;
    const isFranchiseeDisabled = isTerritorySelected;

    const branchTemplate = (branchId: string) =>
      this.branches?.map(b => {
        return html`<option ?selected=${b.id === branchId} value=${b.id}>${b.name}</option>`;
      });
    const allBranchesOption = html`<option value=${emptyGuid} ?selected=${this.selectedBranchId === emptyGuid}>${tlang`All !!branch!!`}</option>`;
    const isBranchDisabled = isEmptyOrSpace(this.selectedFranchiseeId) || isFranchiseeDisabled;

    const userTemplate = (userId: string) =>
      this.users?.map(u => {
        return html`<option ?selected=${u.id === userId} value=${u.id}>${u.name}</option>`;
      });
    const isUserDisabled = isEmptyOrSpace(this.selectedBranchId) || isBranchDisabled;

    return html`
      <div class="page-dashboard-filter">
        <label>Filter Data</label>
        <select @change=${this.dateChangeEvent} id="selectedDateId" title="selectedDateTitle" class="form-select">
          ${dateRangeTemplate(this.selectedDate?.id ?? emptyGuid)}
        </select>
        <select @change=${this.territoryChangeEvent} id="selectedTerritoryId" ?disabled=${isTerritoryDisabled} class="form-select">
          ${allTerritoriesOption}
          ${territoryTemplate(this.selectedTerritoryId ?? emptyGuid)}
        </select>
        <select @change=${this.franchiseeChangeEvent} id="selectedFranchiseeId" ?disabled=${isFranchiseeDisabled} class="form-select">
          ${allFranchiseesOption}
          ${franchiseeTemplate(this.selectedFranchiseeId ?? emptyGuid)}
        </select>
        <select @change=${this.branchChangeEvent} id="selectedBranchId" ?disabled=${isBranchDisabled} class="form-select">
          ${allBranchesOption}
          ${branchTemplate(this.selectedBranchId ?? emptyGuid)}
        </select>
        <select @change=${this.userChangeEvent} id="selectedUserId" ?disabled=${isUserDisabled} class="form-select">
          <option value=${emptyGuid}>${tlang`All Employees`}</option>
          ${userTemplate(this.selectedUserId ?? emptyGuid)}
        </select>
        <button class="btn-primary filter-reset" @click=${this.sendResetFilterEvent}>
          <icon-refresh />
        </button>
      </div>
    `;
  }
}
