import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { marked } from 'marked';

export function asMarkdown(data: string | undefined | null, trimBlanks = true): string {
  if (!data) return '';
  if (trimBlanks)
    data = data
      .split('\n')
      .map(line => line.trimStart())
      .join('\n');
  return marked.parse(data, { async: false }) as string;
}
export function asMarkdownTemplate(data: string | undefined | null, trimBlanks = true) {
  return unsafeHTML(asMarkdown(data, trimBlanks));
}

export function stringsAsMarkdownList(data: string[] | null | undefined) {
  return (data ?? []).map(x => `+ ${x}`).join('\n');
}
