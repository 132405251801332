import { BaseModal, tlang } from '@softtech/webmodule-components';
import { html, TemplateResult } from 'lit-html';
import { WillCallLocation } from '../../api/supplier-api-interface-supplier';
import {
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../../components/save-workflow';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { SupplierSettingsManager } from "../../supplier/common/supplier-settings-manager";
import { customElement, property } from "lit/decorators.js";
import { LitElement } from "lit";
import { clone } from "../../components/clone";
import { showDevelopmentError } from "../../development-error";
import { createConfirmCancelButtons } from "../../components/ui/modal-base";
import { checkValidations } from '../../components/ui/data-entry-screen-helpers';
import { emptyAddress, isAddressValid } from "../../components/ui/maps/map-helpers";
import { emptyGuid, newGuid } from "../../api/guid";
import { getSettingsManager } from "../../supplier/common/supplier-settings";
import { WebModuleAddressEditor } from "../../components/ui/address-editor";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";

@customElement('webmodule-supplier-location-editor')
export class WebModuleSupplierLocationEditor extends LitElement {
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));

  constructor() {
    super();
    const addField = (
        fieldName: string,
        propertyType?: FieldType,
        nullable?: boolean,
        editorFieldName?: string,
        data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
          data ?? (() => this.location),
          fieldName,
          editorFieldName ?? fieldName,
          propertyType ?? FieldType.string,
          nullable ?? false
      );
    };

    addField('title', FieldType.string, false);
    addField('type', FieldType.string, false);
  }

  private _location?: WillCallLocation | undefined;

  public get location(): WillCallLocation | undefined {
    return this._location;
  }

  @property()
  public set location(value: WillCallLocation | undefined) {
    this._location = clone(value);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: {...values},
      bubbles: true,
      composed: true
    };

    //wm-sle WebModule-SupplierLocationEditor
    this.dispatchEvent(new CustomEvent(`wm-sle-${name}`, options));
  }

  locationChangedEvent() {
    this.dispatchCustom('changed', {
      location: this.location
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }

  render() {
    const forms = new FormInputAssistant(this.dataTracker, false);

    const typeOption = (type: string) => {
      return {
        value: type,
        text: tlang`${type}`
      };
    };

    const locationType = () => {
      return JSON.stringify([
        typeOption('Manufacturing Hub'),
        typeOption('Depot'),
        typeOption('Branch')
      ]);
    };

    return html`
      <form class="form-two-col will-call-form">
        <div class="row will-call-info">
          <div class="form-column">${forms.textRequired('title', tlang`Title`, 100)}</div>
          
          <bs-form-radio-group
            class="will-call-type"
            data-label=${tlang`Type`}
            data-id=${this.dataTracker.binder.field('type')}
            ?required=${true}
            .value=${this.location ? this.location.type : 'Manufacturing Hub'}
            .options=${locationType()}
          >
          </bs-form-radio-group>
          
        </div>
        <webmodule-address-editor
            id="location-address-editor"
            .address=${this.location?.willCallAddress}
            .title=${tlang`Address`}
            .readonly=${false}
            .isDefaultShippingVisible=${false}
            @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as WebModuleAddressEditor)}
        ></webmodule-address-editor>
      </form>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private addressModified(addressEditor: WebModuleAddressEditor) {
    if(this.location && addressEditor) {
      this.location.willCallAddress = addressEditor.address ? clone(addressEditor.address) : emptyAddress();
      this.locationChangedEvent();
    }
  }

  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();

    this.dataTracker.applyChangeToValue();
    this.locationChangedEvent();
  };
}

class LocationEditDialog extends BaseModal {
  abandoned?: boolean;
  item: WillCallLocation;
  saved = false;
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private needsSaving = false;

  public mode? = "Edit";

  constructor(item: WillCallLocation) {
    super();
    this.item = item;
  }

  get getLocation(): WillCallLocation {
    return this.item;
  }

  get isFooterVisible(): boolean {
    return true;
  }

  async title(): Promise<string | TemplateResult> {
    return tlang`${this.mode} Will Call Details`;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];

    const location = this.getLocation;

    if (isEmptyOrSpace(location.title))
      errors.push(tlang`Please provide a valid Title`);

    if (isEmptyOrSpace(location.type))
      errors.push(tlang`Please provide a valid Type`);

    if (!isAddressValid(location.willCallAddress))
      errors.push(tlang`Please provide a valid Address`);

    return errors;
  }

  updateLocation(location: WillCallLocation) {
    this.item = location;
    this.needsSaving = true;
  }

  async bodyTemplate(): Promise<TemplateResult> {
    const setLocation = (e: CustomEvent<{ location: WillCallLocation }>) => {
      e.stopImmediatePropagation();
      this.updateLocation(e.detail.location);
    };

    return html`
      <webmodule-supplier-location-editor
          .location="${this.getLocation}"
          @wm-sle-changed="${setLocation}"
      >
      </webmodule-supplier-location-editor>`;
  }

  async performAutoSave(options: SavePromptOptions): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await options.needsSaveEvent();
    } catch (e) {
      await showDevelopmentError(e as Error);
      console.log((e as object).toString());
      return false;
    }

    //this is to let us abandon new items that have had no editing
    if (!needsSave) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);

    if (!(await this.checkValidations())) return false;
    if (isAutoSaving() && !needsSave) return true;

    //this is our basic save indicator
    return await saveWithIndicator(async () => await this.internalSaveData());
  }

  async internalSaveData() {
    if (this.item.id === emptyGuid) {
      this.item.id = newGuid();

      await this.settingsManager.addWillCallLocation(this.item);

    } else {
      await this.settingsManager.updateWillCallLocation(this.item);
    }

    this.saved = await this.settingsManager.saveSettings();
    return this.saved;
  }

  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: false,
      autoSaveEvent: async options => {
        return await this.performAutoSave(options);
      },
      dictionaryName: 'Supplier Locations',
      needsSaveEvent: async () => this.needsSaving,
      abandonSaveEvent: async () => {
        this.abandoned = true;
        this.needsSaving = false;
        return true;
      },
      displaySaveModal: false,
      informationDispatcher: undefined
    };
  }

  async canClose(): Promise<boolean> {
    return this.abandoned || (await canClose(this.getAutoSavePromptOptions()));
  }

  footerTemplate(): TemplateResult | null | undefined {
    const okEvent = async () => {
      if (await this.canClose()) {
        await this.hideModal();
        return;
      }
    };
    const closeEvent = async () => await this.hideModal();
    return createConfirmCancelButtons(tlang`Save Changes`, okEvent, tlang`Cancel`, closeEvent);
  }

  protected modalSize(): string {
    return 'modal-lg';
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }
}

export async function editLocation(item: WillCallLocation | null = null) {
  const location = item ?? new class implements WillCallLocation {
    title = "";
    type = "Manufacturing Hub";
    id = emptyGuid;
    willCallAddress = emptyAddress();
  };

  const dialog = new LocationEditDialog(location);

  if (!item)
    dialog.mode = "Add"

  await dialog.showModal();
  return dialog.saved;
}